import { CircularProgress } from "@mui/material";
import _ from "lodash";
import { Button, Pagination } from "pepsico-ds";
import { useContext } from "react";
import { PartnerContextProvider } from "../../../context/PartnerContext";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import ButtonGroupDynamic from "../ButtonGroupDynamic";
import { ListingComponentContext } from "./ListingComponentContext";

const ListingComponentWrapper = ({
  filterConfig = {
    withDebounce: {
      jsonSchema: null,
      uiSchema: null,
    },
    withoutDebounce: {
      jsonSchema: null,
      uiSchema: null,
    },
  },
  children,
  filterClasses = "",
  customStyles = {},
  filterComponent = null, // Use this if you want custom filter component.
}) => {
  const {
    filterData,
    loading,
    error,
    viewableRecords,
    setFilterData,
    resetPagination,
    debouncedFilterData,
    handleDebouncedFilterChange,
    pagination,
    setPagination,
    totalPageCount,
    viewType,
    setViewType,
    handleClearFilters,
  } = useContext(ListingComponentContext);

  const onFilterChange = (data) => {
    const cleanedData = _.omitBy(
      data,
      (value) => _.isArray(value) && _.isEmpty(value)
    );
    resetPagination();
    if (!Array.isArray(data?.isActive)) {
      setFilterData(cleanedData);
      return;
    }
    if (cleanedData?.isActive.length === 0) {
      setFilterData({ ...cleanedData, isActive: null });
    } else if (cleanedData.isActive[0] === "true") {
      setFilterData({ ...cleanedData, isActive: true });
    } else if (cleanedData.isActive[0] === "false") {
      setFilterData({ ...cleanedData, isActive: false });
    } else {
      setFilterData(cleanedData);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100% - 3rem)",
        ...customStyles,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {filterComponent || (
          <div style={{ display: "flex", flex: 9 }} className={`${filterClasses}`}>
            {filterConfig.withDebounce?.jsonSchema &&
              filterConfig.withDebounce?.uiSchema && (
                <div className="debounced-filters">
                  <JsonFormsWrapper
                    jsonschema={filterConfig.withDebounce.jsonSchema}
                    uischema={filterConfig.withDebounce.uiSchema}
                    data={debouncedFilterData}
                    onChange={(data) => handleDebouncedFilterChange(data)}
                  />
                </div>
              )}
            {filterConfig.withoutDebounce?.jsonSchema &&
              filterConfig.withoutDebounce?.uiSchema && (
                <div className="nonDebounced-filters">
                  <PartnerContextProvider>
                    <JsonFormsWrapper
                      jsonschema={filterConfig.withoutDebounce.jsonSchema}
                      uischema={filterConfig.withoutDebounce.uiSchema}
                      data={filterData}
                      onChange={(data) => onFilterChange(data)}
                    />
                  </PartnerContextProvider>
                </div>
              )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  color: "#005CBC",
                  marginLeft: "8px",
                  marginTop: "8px",
                  marginBottom: "14px",
                }}
                variant="primaryInverse"
                size="small"
                onClick={handleClearFilters}
              >
                Clear all
              </Button>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
            flex: 2,
          }}
        >
          <ButtonGroupDynamic
            items={[
              {
                icon: "view_module",
                onClick: () => {
                  setViewType("grid");
                },
              },
              {
                icon: "view_list",
                onClick: () => {
                  setViewType("list");
                },
              },
            ]}
            size="medium"
            spacing="2px"
            variant="toggle"
            selectedIndex={viewType === "grid" ? 0 : 1}
          />
        </div>
      </div>
      {loading && (
        <div
          data-testid="loading-state"
          className="display-flex align-items-center justify-content-center"
          style={{ flex: "1" }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && viewableRecords.length === 0 && (
        <div
          className="display-flex align-items-center justify-content-center"
          style={{
            flex: "1",
            padding: "50px 0px 50px 0px",
          }}
        >
          <p>No Records found</p>
        </div>
      )}
      {loading || error ? <></> : children}
      {!loading && !error && totalPageCount > 0 && (
        <div
          style={{
            padding: "16px 0px 16px 0px",
            display: "flex",
            justifyContent: "center",
            flexShrink: 0,
          }}
        >
          <Pagination
            currentPage={pagination.currentPage}
            onUpdate={(newIndex) =>
              setPagination({ ...pagination, currentPage: newIndex })
            }
            pageCount={totalPageCount}
            size="medium"
            variant="number"
          />
        </div>
      )}
    </div>
  );
};

export default ListingComponentWrapper;
