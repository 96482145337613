import { useQuery } from "@apollo/client";
import { createAjv } from "@jsonforms/core";
import _ from "lodash";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  rewardBasicSetupJsonSchema,
  rewardImageSetupJsonSchema,
} from "../components/rewards/CreateRewardsModal/schemas";
import { loadUiComponent } from "../graphql/queries/loadUiComponent";

const RewardContext = createContext({
  rewardState: {},
  setRewardState: () => null,
});

const RewardProvider = ({ children }) => {
  const initialState = useMemo(
    () => ({
      isActive: true,
      partnerCodeType: false,
      rewardItemGroup: false,
      rewardItemGroupId: "",
      rewardTypeData: {},
    }),
    []
  );
  const [rewardState, setRewardState] = useState(initialState);
  const [errors, setErrors] = useState([{}]);
  const [optionsLoading, setOptionsLoading] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });
  const partnerIdRef = useRef(rewardState?.partnerId);
  const { data, loading: loadingSpecifications } = useQuery(loadUiComponent, {
    skip: !rewardState.schema,
    variables: {
      slug:
        rewardState.partnerType === "INTEGRATED"
          ? rewardState.partnerName?.toLowerCase().replace(/\s+/g, "-")
          : "partner-code-group",
    },
    onError: () =>
      setToast({
        open: true,
        type: "error",
        title: "Something went wrong while loading specifications.",
        description: "Please try again later.",
      }),
  });
  const ajv = useMemo(
    () =>
      createAjv().addKeyword({
        keyword: "isNotEmpty",
        type: "string",
        validate: function (schema, data) {
          return typeof data === "string" && data.trim() !== "";
        },
        errors: false,
      }),

    []
  );

  const updateErrors = useCallback(
    (basicSetupSchema, imageSetupSchema, specificiationsSchema, data) => {
      ajv.validate(basicSetupSchema, data);
      const basicSetupErrors = ajv.errors || [];
      ajv.validate(imageSetupSchema, data);
      const imageSetupErrors = ajv.errors || [];
      ajv.validate(specificiationsSchema, data.rewardTypeData || {});
      const specificiationsErrors = ajv.errors || [];
      setErrors([
        ...basicSetupErrors,
        ...imageSetupErrors,
        ...specificiationsErrors,
      ]);
    },
    [ajv]
  );

  const loadRewardState = useCallback(
    (data) => {
      const updatedState = {
        ...data,
        rewardItemGroupId: data.rewardItemGroupId || "",
        rewardTypeData: data.rewardTypeData || {},
      };
      setRewardState(updatedState);
      partnerIdRef.current = updatedState.partnerId;
      updateErrors(
        rewardBasicSetupJsonSchema,
        rewardImageSetupJsonSchema,
        updatedState.schema || {},
        updatedState
      );
    },
    [updateErrors]
  );

  const updateRewardState = useCallback(
    (data) => {
      if (data?.partnerId !== partnerIdRef.current) {
        const partnerIdDependentProps = [
          "type",
          "rewardTypeId",
          "rewardUnitId",
          "schema",
        ];
        partnerIdRef.current = data?.partnerId;
        const updatedState = {
          ..._.omit(data, partnerIdDependentProps),
          partnerCodeType: false,
          rewardItemGroup: false,
          rewardItemGroupId: "",
        };
        setRewardState(updatedState);
        updateErrors(
          rewardBasicSetupJsonSchema,
          rewardImageSetupJsonSchema,
          updatedState.schema || {},
          updatedState
        );
        return;
      }
      const updatedState = {
        ...data,
        partnerCodeType: !!data.type?.match(/partner.*code.*group/i),
        rewardItemGroup: !!data.type?.match(/partner.*code.*group/i),
      };
      setRewardState(updatedState);
      updateErrors(
        rewardBasicSetupJsonSchema,
        rewardImageSetupJsonSchema,
        updatedState.schema || {},
        updatedState
      );
    },
    [updateErrors]
  );

  const addOptionLoading = useCallback((id) => {
    setOptionsLoading((prev) => (!prev.includes(id) ? [...prev, id] : prev));
  }, []);

  const removeOptionLoading = useCallback((id) => {
    setOptionsLoading((prev) => prev.filter((optionId) => optionId !== id));
  }, []);

  const resetRewardState = useCallback(() => {
    updateRewardState(initialState);
  }, [updateRewardState, initialState]);

  useEffect(() => setIsLoadingOptions(!!optionsLoading.length), [optionsLoading]);

  const value = useMemo(
    () => ({
      rewardState,
      updateRewardState,
      loadRewardState,
      resetRewardState,
      isLoadingOptions,
      addOptionLoading,
      removeOptionLoading,
      loadingSpecifications,
      errors,
      toast,
      setToast,
      uiSchema: loadingSpecifications ? null : data?.loadUiComponent?.uiSchema,
    }),
    [
      rewardState,
      updateRewardState,
      loadRewardState,
      resetRewardState,
      isLoadingOptions,
      addOptionLoading,
      removeOptionLoading,
      loadingSpecifications,
      errors,
      toast,
      data?.loadUiComponent?.uiSchema,
    ]
  );

  return <RewardContext.Provider value={value}>{children}</RewardContext.Provider>;
};

export { RewardContext, RewardProvider };
