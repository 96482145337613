import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { getCodeGroups } from "../../../graphql/queries/codeGroupQueries";
import { CODE_GROUP_LIFE_CYCLE_STATUS } from "../../../utils/constants";

const SelectedCodeGroups = ({ data }) => {
  const { data: apiList, loading } = useQuery(getCodeGroups, {
    fetchPolicy: "cache-first",
    variables: { filter: { lifecycleStatus: CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE } },
  });
  const formattedGroups = useMemo(() => {
    const formattedSelectedGroups = [];
    const selectedIds = data.code_group_ids || [];
    if (selectedIds?.length && apiList?.listCodeGroups?.length) {
      selectedIds.forEach((id) => {
        const formattedGroup = apiList?.listCodeGroups?.find(
          (codeGroup) => codeGroup.codeGroupId === id
        );
        if (formattedGroup) {
          formattedSelectedGroups.push(formattedGroup);
        }
      });
      return formattedSelectedGroups;
    }
  }, [data, apiList]);

  return (
    <div className="additional-fields-container">
      <div className="field-pair" style={{ flexBasis: "31% !important" }}>
        <span className="field-label">Selected Code Groups: </span>
      </div>
      <div className="field-pair-two-column">
        {loading ? (
          <CircularProgress />
        ) : (
          <span className="field-value">
            {formattedGroups?.length
              ? formattedGroups
                  ?.map((codeGroup) => codeGroup?.basicSetup?.codeGroupName || "")
                  .join(", ")
              : "-"}
          </span>
        )}
      </div>
    </div>
  );
};

export default SelectedCodeGroups;
