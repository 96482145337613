import { useJsonForms } from "@jsonforms/react";
import { defaultTo } from "lodash";
import { Icon, TextInput, Tooltip } from "pepsico-ds";
import PropTypes from "prop-types";
import * as React from "react";
import "./text-input.scss";

const TextInputField = ({
  value,
  updateValue,
  label,
  errors,
  schema,
  uiSchema,
  required,
  disabled,
  visible,
}) => {
  const inputRef = React.useRef();
  const getUpdatedValue = (schema, val) => {
    if (schema.type === "number" || schema.type === "integer") {
      return val;
    }
    return val.trim();
  };
  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;

  const [isFocused, setIsFocused] = React.useState(false);
  const errMsg =
    schema.message && errors.indexOf("must match pattern") > -1
      ? schema.message
      : errors;

  const isNumberOrInteger = (type) => type === "number" || type === "integer";

  const isValidLength = (val, maxLength) => !maxLength || val.length <= maxLength;

  const parseValue = (val) => {
    let parsedVal = parseFloat(val);
    if (parsedVal < 0 || Number.isNaN(parsedVal)) {
      parsedVal = "";
    }
    return parsedVal;
  };

  const handleNumberOrIntegerChange = (val, schema, uiSchema) => {
    if (!isValidLength(val, uiSchema.maxLength)) {
      return null;
    }
    return parseValue(val);
  };

  const onChange = (val) => {
    if (isNumberOrInteger(schema.type)) {
      val = handleNumberOrIntegerChange(val, schema, uiSchema);
      if (val === null) {
        return;
      }
    }
    const updatedValue = getUpdatedValue(schema, val);
    updateValue(updatedValue);
  };

  const isValidCharacter = (char, validCharacters) =>
    validCharacters.includes(char) || char === "Backspace";

  const handleKeyDown = (e, validCharacters, value, isFocused) => {
    const char = e.key;
    if (value?.includes(char) && isFocused) {
      e.preventDefault();
      return;
    }
    if (!isValidCharacter(char, validCharacters)) {
      e.preventDefault();
    }
  };

  const onKeyDown = (e) => {
    if (uiSchema.includedChars?.key && jsonformState[uiSchema.includedChars.key]) {
      const validCharacters = jsonformState[uiSchema.includedChars.key];
      const isUnique = uiSchema.includedChars.isUnique;
      //if unique then prevent duplicate characters.
      const isCharacterAlreadyPresent = value.includes(e.key);
      if (isUnique && isCharacterAlreadyPresent) {
        e.preventDefault();
        return;
      }
      handleKeyDown(e, validCharacters, value, uiSchema.isFocused);
    }
  };
  const diableButton = disabled || uiSchema.readOnly ? "disable-button" : "";
  // Display info icon with info text
  if (inputRef.current && uiSchema?.info) {
    const infoIcon =
      inputRef.current?.parentElement?.previousSibling?.getElementsByClassName(
        "material-icons-outlined"
      );
    if (infoIcon?.length && infoIcon?.[0]) {
      infoIcon[0].title = uiSchema.info;
    }
  }
  return visible ? (
    <>
      <div className="input-text-wrapper">
        <TextInput
          required={required}
          className={`${uiSchema?.className ?? ""} ${diableButton} inputType-${schema?.type === "integer" ? "number" : schema?.type}`}
          disabled={disabled || uiSchema.readOnly || uiSchema.disabled}
          maxLength={uiSchema.maxLength ?? ""}
          onKeyDown={onKeyDown}
          type={schema?.type === "integer" ? "number" : schema?.type || "text"}
          error={isFocused && !disabled && errMsg ? errMsg : undefined}
          label={label}
          info={uiSchema?.info || ""}
          showLabelIcon={!!uiSchema?.info}
          ref={inputRef}
          onUpdate={onChange}
          onBlur={() => {
            const trimmedValue = value?.trim?.() ?? value;
            updateValue(trimmedValue);
            !isFocused && setIsFocused(true);
          }}
          value={defaultTo(value, undefined)}
          placeholderText={uiSchema.placeholder ?? ""}
          readOnly={uiSchema.readonly}
          helperText={uiSchema.helperText}
          min={uiSchema.min}
        />
        {uiSchema?.tooltipText && uiSchema?.tooltipText?.length > 0 && (
          <div className="tooltip-text">
            <Tooltip text={uiSchema.tooltipText}>
              <Icon alt="" icon="info" size="small" />
            </Tooltip>
          </div>
        )}
      </div>
      {uiSchema.helpText && (
        <div className="input-help-text">
          <Tooltip text={uiSchema.helpText} className="help-tooltip">
            <Icon icon="info" size="small" />
          </Tooltip>
          Help
        </div>
      )}
    </>
  ) : null;
};

TextInputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  schema: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string, // Fixed 'schema.message' validation
  }).isRequired,
  uiSchema: PropTypes.shape({
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    info: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

TextInputField.defaultProps = {
  value: "",
  errors: "",
  required: false,
  disabled: false,
  uiSchema: {},
};

export default TextInputField;
