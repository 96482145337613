import { withJsonFormsControlProps } from "@jsonforms/react";

const SummaryTextComponent = ({ data, label, uischema }) => {
  return (
    <div
      className={`details-section-value ${uischema.long ? "flex-direction-column" : ""}`}
    >
      <span className="details-section-label">{label}</span>
      <span>
        {uischema.options?.labelMapping
          ? uischema.options?.labelMapping[data]
          : data}
      </span>
    </div>
  );
};

export const SummaryTextControl = withJsonFormsControlProps(SummaryTextComponent);
