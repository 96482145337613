export const FeatureToggle = {
  newFileUploader: "REACT_APP_NEW_FILE_UPLOADER",
  imagePicker: "REACT_APP_IMAGE_PICKER",
  memberListExtra: "REACT_APP_MEMBER_LIST_EXTRA_FIELDS",
  showActivityIdAtSummaryPage: "SHOW_ACTIVITY_ID_AT_SUMMARY_PAGE",
  isDetailedOutcomeEnabledInSummaryPage:
    "IS_DETAILED_OUTCOME_ENABLED_IN_SUMMARY_PAGE",
};

export const isFeatureEnabled = (flagKey) => {
  const ftValue = window?.[flagKey];
  if (ftValue === undefined) {
    console.warn(`[FT] Feature "${flagKey}" is not defined!`);
  }
  return ftValue == "true";
};
