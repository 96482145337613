import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import axios from "axios";
import { File } from "pepsico-ds";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";

const PartnerFileUploadComponent = (props) => {
  const { handleChange, label, data, path, uischema, required, visible } = props;

  const accept = uischema?.accept?.toString() || ".txt, .csv";
  const description = uischema?.description || "Select a file";
  const [variant, setVariant] = useState("edit");
  const [errorMessage, setErrorMessage] = useState("");
  const [rewardItemFileData, setRewardItemFileData] = useState(data || []);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const { globalState } = useContext(GlobalConfigContext);

  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;
  const [disabled, setDisabled] = useState(false);

  const programId =
    globalState?.programConfig?.id || "018a2bd5-fdc2-77ce-9367-7d700186d5ae";
  const accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))
    ?.accessToken?.accessToken;

  const uploadFile = useCallback(
    async (file) => {
      setVariant("uploading");
      setIsUploading(true);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadUrl = window.REWARD_PARTNER_GROUP_FILE_MANAGER_BASE_URI;
        const partnerId = localStorage.getItem("partnerId");
        const endpoint = `${uploadUrl}upload?program_id=${programId}&partner_id=${partnerId}`;

        const response = await axios.post(endpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
        });

        const uploadedFileData = response.data.map((currentFile) => ({
          id: currentFile.id,
          name: currentFile.name,
          size: currentFile.size,
          type: currentFile.type,
          url: null,
          metadata: {
            description: currentFile.metadata.description,
            tags: currentFile.metadata.tags,
            uploadedBy: currentFile.metadata.uploaded_by,
            uploadedAt: currentFile.metadata.uploaded_at,
            containerName: currentFile.metadata.container_name,
          },
        }));

        setRewardItemFileData((prev) => [...prev, ...uploadedFileData]);
        setVariant("edit");
        setErrorMessage("");
      } catch (error) {
        const errMsg =
          error?.response?.data?.extension?.issues[0]?.detail ||
          error?.response?.data?.extension?.issues?.detail ||
          "Something went wrong";
        setErrorMessage("Upload error: " + errMsg);
        setVariant("edit");
      } finally {
        setIsUploading(false);
      }
    },
    [programId, accessToken]
  );

  const handleFileChange = (file) => {
    if (["text/plain", "text/csv"].includes(file.type)) {
      setErrorMessage("");
      uploadFile(file);
    } else {
      setErrorMessage("Invalid file type");
    }
  };

  const handleDeleteFile = useCallback(
    async (fileId) => {
      const uploadUrl = window.REWARD_PARTNER_GROUP_FILE_MANAGER_BASE_URI;
      const programName = window.REACT_APP_PROGRAM;
      const partnerId = localStorage.getItem("partnerId");
      const file = rewardItemFileData[fileId];

      if (!file) {
        setErrorMessage("File not found.");
        return;
      }

      const fileName = file.name?.split("/")[1];
      if (!fileName) {
        setErrorMessage("Invalid file name format.");
        return;
      }

      const endpoint = `${uploadUrl}/${programName}/${programId}/${partnerId}/${fileName}`;

      try {
        const response = await axios.delete(endpoint, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response.status === 204) {
          setRewardItemFileData((prevData) =>
            prevData.filter((_, index) => index !== fileId)
          );
          setErrorMessage("");
        } else {
          setErrorMessage("Failed to delete file. Please try again.");
        }
      } catch (error) {
        setErrorMessage("An error occurred while deleting the file.");
        console.error("Error deleting file:", error);
      }
    },
    [rewardItemFileData, programId, accessToken]
  );

  useEffect(() => {
    handleChange(path, rewardItemFileData);
  }, [handleChange, path, rewardItemFileData]);

  useEffect(() => {
    if (jsonformState?.partnerId) {
      localStorage.setItem("partnerId", jsonformState?.partnerId);
      setDisabled(false);
      setErrorMessage("");
    } else {
      setDisabled(true);
      setRewardItemFileData([]);
      setErrorMessage("Please select a partner to upload files");
    }
  }, [jsonformState?.partnerId]);

  return visible ? (
    <>
      <File
        key={errorMessage}
        data-testid="image-uploader-file"
        accept={accept}
        description={description}
        multiple
        buttonLabel="Upload files"
        onUpdate={handleFileChange}
        variant={variant}
        onDelete={handleDeleteFile}
        files={rewardItemFileData.map((file) => file?.name?.split("/")?.pop())}
        disabled={isUploading || disabled}
        progressPerc={uploadProgress}
        progressLabel="Uploading"
        label={label}
        required={required}
      />
      {errorMessage && <p style={{ color: "red", fontSize: 12 }}>{errorMessage}</p>}
    </>
  ) : null;
};

export const PartnerFileUploadControl = withJsonFormsControlProps(
  PartnerFileUploadComponent
);
