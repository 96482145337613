import PropTypes from "prop-types";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import { codeGroupActionButton } from "./codeGroupActionButton";
import "./codeGroupTable.scss";

const CodeGroupTable = ({ codeGroupData, handleActionButtons }) => {
  return (
    <div className="codegroup-list-container">
      <table className="codegroup-list-table">
        <thead>
          <tr>
            <th className="codegroup-column-status">Status</th>
            <th className="codegroup-column-title">Name</th>
            <th className="codegroup-column-description">Producer Company</th>
            <th className="codegroup-column-total-codes">Code Count</th>
            <th className="codegroup-column-total-codes">Used Code Count</th>
            <th className="codegroup-column-active-date">Start Date</th>
            <th className="codegroup-column-active-date">End Date</th>
            <th className="codegroup-column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {codeGroupData.map((item, index) => {
            const basicSetup = item.basicSetup || {};
            const producerPackaging = item.producerPackaging || {};

            const codeGroupName = basicSetup.codeGroupName || "-";
            const codeActiveStartDate = basicSetup.codeActiveStartDate || null;
            const codeActiveEndDate = basicSetup.codeActiveEndDate || null;

            const producerCompany = producerPackaging.producerCompany || "-";
            const codesUsedCount =
              item.codesUsedCount !== undefined && item.codesUsedCount !== null
                ? item.codesUsedCount
                : "-";
            const codesGeneratedCount =
              item.codesGeneratedCount !== undefined &&
              item.codesGeneratedCount !== null
                ? item.codesGeneratedCount
                : "-";
            const compositeStatus = item.compositeStatus || "-";
            const compositeStatusClass = compositeStatus
              .toLowerCase()
              .replace(/\s/g, "-");

            return (
              <tr key={index}>
                <td className="codegroup-widget-status-type">
                  <span
                    className={`codegroup-lifecycle-status ${compositeStatusClass}`}
                  >
                    {compositeStatus}
                  </span>
                </td>
                <td
                  className={`${
                    /\s/.test(codeGroupName) ? "" : "codegroup-no-space-title"
                  }`}
                >
                  {codeGroupName}
                </td>
                <td>{producerCompany}</td>
                <td>{codesGeneratedCount}</td>
                <td>{codesUsedCount}</td>
                <td>
                  {codeActiveStartDate
                    ? new Date(codeActiveStartDate).toLocaleDateString()
                    : "-"}
                </td>
                <td>
                  {codeActiveEndDate
                    ? new Date(codeActiveEndDate).toLocaleDateString()
                    : "-"}
                </td>
                <td>
                  <div className="codegroup-button-group-container">
                    <ButtonGroupDynamic
                      items={codeGroupActionButton(item, handleActionButtons)}
                      spacing="0px"
                      size="medium"
                      variant="control"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

CodeGroupTable.propTypes = {
  codeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      administrationStatus: PropTypes.string,
      compositeStatus: PropTypes.string,
      codesGeneratedCount: PropTypes.number,
      basicSetup: PropTypes.shape({
        codeGroupName: PropTypes.string,
        codeActiveStartDate: PropTypes.string,
        codeActiveEndDate: PropTypes.string,
      }),
      producerPackaging: PropTypes.shape({
        producerCompany: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default CodeGroupTable;
