import { useCallback } from "react";
import { useGetUserClaimsOkta } from "./useGetUserClaimsOkta";

export const useHasAccessPermission = () => {
  const userInfo = useGetUserClaimsOkta();
  const roles = userInfo?.pepappwsproles ?? null;

  const hasAccess = useCallback(
    (requiredAccess) => {
      if (requiredAccess.includes("all")) return true;

      if (roles === null || !Array.isArray(roles)) return false;

      return requiredAccess.some((access) => roles.includes(access));
    },
    [roles]
  );

  return hasAccess;
};
