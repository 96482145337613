import { gql } from "@apollo/client";

export const listRewardGroups = gql`
  query ListRewardGroups($rewardGroupId: String) {
    listRewardGroups(filter: { rewardGroupId: $rewardGroupId }) {
      id
      campaignId
      type
      name
      image
      numberOfCodeScansRequiredToWin
      position
      description
      maxParticipationPerInterval
      intervalPositionCode
      amount
      numberOfWinnersLimit
      completed
      isSpinWheelEnabled
      hasJourneys
      rewardGroupRewards {
        id
        rewardId
        rewardGroupId
        position
        quantity
        chanceToWinPercentage
        walletWithdrawalDate
        totalUserWinLimit
        startDate
        endDate
        isParent
        isExtra
        parentRewardGroupRewardId
        hasJourneys
        journeySummaries {
          completedMilestones
          totalMilestones
          consumerCount
        }
        rewardInfo {
          name
        }
      }
    }
  }
`;
