import { useMutation, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { createContext, useEffect, useState } from "react";
import saveBanner from "../pages/banners/graphQl/mutations/saveBanner";
import getBannerList from "../pages/banners/graphQl/queries/bannerList";
import { validateSingleJsonFormsData } from "../utils/validateJsonFormsData";

// Create the  BannerContext
const BannerContext = createContext();

// Create the BannerProvider component
const BannerProvider = ({ children }) => {
  // Define the initial state
  const initialState = {
    id: null,
    title: "",
    slug: "",
    description: "",
    activeStartDate: "",
    activeEndDate: "",
    contentDefinitionSlug: "banner-content",
    segments: [],
    tags: [],
    category: "",
    paused: false,
    contentData: {
      button_color: "",
      banner_type: "",
      position: "",
      image_url: "",
      customize_banner: false,
      action_type: "",
      show_button: false,
      image_description: "{{user.username}}",
      image_title: "{{user.username}}",
    },
  };

  const uiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "SimpleGroup",
        label: "Basic Setup",
        labelStyles: { display: "none" },
        elements: [
          {
            type: "VerticalLayout",
            className: "bannerImage",
            elements: [
              {
                rule: {
                  condition: {
                    schema: {
                      const: "HERO",
                    },
                    scope: "#/properties/contentData/properties/banner_type",
                  },
                  effect: "SHOW",
                },
                component: "singleImageUpload",
                type: "Control",
                scope: "#/allOf/0/then/properties/contentData/properties/image_url",
                accept: ["image/png", "image/jpg", "image/jpeg"],
                label: "Banner Image*",
                requiredResolution: true,
                maxWidth: 1080,
                maxHeight: 660,
              },
              {
                rule: {
                  condition: {
                    schema: {
                      const: "MINI",
                    },
                    scope: "#/properties/contentData/properties/banner_type",
                  },
                  effect: "SHOW",
                },
                component: "singleImageUpload",
                type: "Control",
                scope: "#/allOf/1/then/properties/contentData/properties/image_url",
                accept: ["image/png", "image/jpg", "image/jpeg"],
                label: "Banner Image*",
                requiredResolution: true,
                maxWidth: 984,
                maxHeight: 300,
              },
            ],
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/contentData/properties/banner_type",
                component: "muiSelect",
                label: "Banner Type*",
                className: "banner-type",
                rule: {
                  effect: "ENABLE",
                  condition: {
                    scope: "#/properties/id",
                    schema: {
                      const: null,
                    },
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/contentData/properties/position",
                component: "textField",
                label: "Position In Homepage*",
                maxLength: 5,
              },
            ],
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/activeStartDate",
                component: "dateTimePicker",
                minimum: "currentDateTime",
                label: "Active Start date",
                rule: {
                  effect: "ENABLE",
                  condition: {
                    scope: "#/properties/id",
                    schema: {
                      const: null,
                    },
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/activeEndDate",
                component: "dateTimePicker",
                minimum: "currentDateTime",
                label: "Active End Date",
                rule: {
                  condition: {
                    schema: {
                      gt: "activeStartDate",
                    },
                  },
                  effect: "VALIDATE",
                },
              },
            ],
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                label: "Title*",
                placeholder: "Type here",
                scope: "#/properties/contentData/properties/title",
                component: "textField",
              },
            ],
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/contentData/properties/description",
                component: "textField",
                label: "Description*",
                placeholder: "Type here",
              },
            ],
          },
        ],
      },
      {
        type: "SimpleGroup",
        label: "Specification",
        labelStyles: { display: "none" },
        elements: [
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "HorizontalLayout",
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/contentData/properties/action_type",
                    component: "muiSelect",
                    label: "Link Type*",
                    // rule: {
                    //   effect: "ENABLE",
                    //   condition: {
                    //     scope: "#/properties/id",
                    //     schema: {
                    //       const: null
                    //     }
                    //   }
                    // }
                  },
                  {
                    type: "Control",
                    scope: "#/properties/contentData/properties/action_url",
                    component: "textField",
                    label: "URL",
                    placeholder: "Type here",
                    rule: {
                      effect: "DISABLE",
                      condition: {
                        scope: "#",
                        schema: {
                          anyOf: [
                            {
                              properties: {
                                contentData: {
                                  properties: {
                                    action_type: {
                                      const: "DO_NOT_REDIRECT",
                                    },
                                  },
                                  required: ["action_type"],
                                },
                              },
                            },
                            // {
                            //   properties: {
                            //     id: {
                            //       not: { const: null },
                            //     },
                            //   },
                            // },
                          ],
                        },
                      },
                    },
                  },
                ],
              },
            ],
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/contentData/properties/customize_banner",
                component: "toggle",
                layoutItemStyles: {
                  flexGrow: 1,
                },
              },
              {
                type: "VerticalLayout",
                layoutItemStyles: {
                  flexGrow: 3,
                },
                elements: [
                  {
                    type: "Control",
                    scope: "#/properties/contentData/properties/image_title",
                    component: "textField",
                    label: "Image Text Title",
                    placeholder: "{{user.username}} ",
                    maxLength: 33,
                    rule: {
                      effect: "DISABLE",
                      condition: {
                        scope:
                          "#/properties/contentData/properties/customize_banner",
                        schema: {
                          const: false,
                        },
                      },
                    },
                  },
                  {
                    type: "Control",
                    scope: "#/properties/contentData/properties/image_description",
                    component: "textarea",
                    placeholder: "{{user.username}} ",
                    label: "Image Text Description",
                    maxLength: 60,
                    rule: {
                      effect: "DISABLE",
                      condition: {
                        scope:
                          "#/properties/contentData/properties/customize_banner",
                        schema: {
                          const: false,
                        },
                      },
                    },
                  },
                  {
                    type: "HorizontalLayout",
                    elements: [
                      {
                        type: "Control",
                        scope: "#/properties/contentData/properties/show_button",
                        component: "toggle",
                        rule: {
                          effect: "DISABLE",
                          condition: {
                            scope:
                              "#/properties/contentData/properties/customize_banner",
                            schema: {
                              const: false,
                            },
                          },
                        },
                      },
                      {
                        type: "Control",
                        scope: "#/properties/contentData/properties/button_text",
                        component: "textField",
                        placeholder: "Type here",
                        label: "Button Text",
                        maxLength: 15,
                        rule: {
                          effect: "DISABLE",
                          condition: {
                            scope: "#/properties/contentData/properties/show_button",
                            schema: {
                              const: false,
                            },
                          },
                        },
                      },
                      {
                        type: "Control",
                        scope: "#/properties/contentData/properties/button_color",
                        component: "muiSelect",
                        label: "Button Color",
                        rule: {
                          effect: "DISABLE",
                          condition: {
                            scope: "#/properties/contentData/properties/show_button",
                            schema: {
                              const: false,
                            },
                          },
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            rule: {
              effect: "HIDE",
              condition: {
                scope: "#/properties/contentData/properties/banner_type",
                schema: {
                  const: "MINI",
                },
              },
            },
          },
        ],
      },
      {
        type: "SimpleGroup",
        label: "Segments",
        labelStyles: { display: "none" },
        elements: [
          {
            type: "Control",
            scope: "#/properties/segments",
            component: "bannersegmentselect", // Ensure this matches the registered component name
            label: "Select Segments*",
          },
        ],
      },
    ],
  };

  const jsonSchema = {
    allOf: [
      {
        if: {
          properties: {
            contentData: {
              properties: {
                banner_type: {
                  const: "HERO",
                },
              },
            },
          },
        },
        then: {
          properties: {
            contentData: {
              properties: {
                image_url: {
                  type: "string",
                  items: { type: "string" },
                  format: "uri",
                },
              },
            },
          },
        },
      },
      {
        if: {
          properties: {
            contentData: {
              properties: {
                banner_type: {
                  const: "MINI",
                },
              },
            },
          },
        },
        then: {
          properties: {
            contentData: {
              properties: {
                image_url: {
                  type: "string",
                  items: { type: "string" },
                  format: "uri",
                },
              },
            },
          },
        },
      },
    ],
    $schema: "http://json-schema.org/draft-07/schema#",
    title: "Banner Content",
    type: "object",
    properties: {
      title: {
        type: "string",
        description: "title for banner",
      },
      description: {
        type: "string",
      },
      activeStartDate: {
        type: "string",
      },
      activeEndDate: {
        type: "string",
      },
      segments: {
        type: "array",
        items: {
          type: "string",
        },
        title: "Segments",
        description: "Select the segments for this banner",
      },
      contentData: {
        type: "object",
        properties: {
          position: {
            type: "integer",
            title: "Position In Homepage",
          },
          image_url: {
            type: "string",
            items: { type: "string" },
            format: "uri",
          },
          banner_type: {
            type: "string",
            label: "Banner type*",
            header: "Select Type",
            enum: ["HERO", "MINI"],
          },
          action_type: {
            type: "string",
            label: "Link type*",
            header: "Select Type",
            enum: ["WEB_LINK", "DEEP_LINK", "DO_NOT_REDIRECT"],
          },
          action_url: {
            type: "string",
            format: "uri",
          },
          customize_banner: {
            type: "boolean",
            title: "Customize Banner",
          },
          show_button: {
            type: "boolean",
            title: "Show Button",
          },
          button_text: {
            type: "string",
          },
          button_color: {
            type: "string",
            label: "Button color",
            header: "Select color",
            enum: ["Light", "Dark"],
          },
          image_title: {
            type: "string",
          },
          image_description: {
            type: "string",
          },
          title: {
            type: "string",
            description: "title for banner",
          },
          description: {
            type: "string",
          },
        },
        required: [
          "title",
          "description",
          "position",
          "image_url",
          "banner_type",
          "action_type",
        ],
      },
    },
    /*
    required: [
      "activeStartDate",
      "activeEndDate",
    ]
    */
  };

  const initialFilter = {
    filter: {
      contentDefinitionSlug: "banner-content",
    },
  };

  // Create the state using useState
  const [bannerState, setBannerState] = useState(initialState);
  const [copyBannerState, setCopyBannerState] = useState({});
  const [bannerList, setBannerList] = useState([]);
  const [filterVariable, setFilterVariables] = useState(null);
  const [variables, setVariables] = useState(initialFilter);
  const [load, setLoad] = useState(false);
  const [saveBannerMutation] = useMutation(saveBanner);
  const [errMsg, setErrMsg] = useState([]);
  const [successMsg, setSuccessMsg] = useState("");
  const [successTitle, setSuccessTitle] = useState({});
  const [actionStatus, setActionStatus] = useState(null);
  const [timeZone, setTimeZone] = useState(null);

  const { loading, data, refetch } = useQuery(getBannerList, {
    variables: variables,
    onCompleted: () => {
      setLoad(false);
    },
    onError: () => {
      setLoad(false);
    },
  });

  const isValidURL = (string) => {
    try {
      const newUrl = new URL(string);
      return ["http:", "https:"].includes(newUrl.protocol);
    } catch {
      return false;
    }
  };

  const vlaidateURL = (data) => {
    if (
      data?.contentData?.action_type?.length > 0 &&
      data?.contentData?.action_type !== "DO_NOT_REDIRECT"
    ) {
      if (!isValidURL(data?.contentData?.action_url)) {
        return true;
      }
    }
  };

  const validateRules = (data) => {
    const tempErr = [];

    const validateActionType =
      (data?.contentData?.action_type !== "DO_NOT_REDIRECT" &&
        !data?.contentData?.action_url) ||
      (data?.contentData?.action_type !== "DO_NOT_REDIRECT" &&
        data?.contentData?.action_url?.length === 0)
        ? true
        : vlaidateURL(data);
    if (validateActionType) {
      tempErr.push("Please enter the valid action URL with URI format!");
    }
    if (data?.contentData?.customize_banner) {
      if (
        !data?.contentData?.image_title ||
        data?.contentData?.image_title?.length === 0 ||
        !data?.contentData?.image_description ||
        data?.contentData?.image_description?.length === 0
      ) {
        tempErr.push("Please set image title and description!");
      }
    }
    if (data?.contentData?.show_button) {
      if (
        !data?.contentData?.button_text ||
        data?.contentData?.button_text?.length === 0 ||
        !data?.contentData?.button_color ||
        data?.contentData?.button_color?.length === 0
      ) {
        tempErr.push("Please set button text and color!");
      }
    }
    setErrMsg(tempErr);
    if (tempErr?.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const validateImageDescription = (data) => {
    const findBrace = (
      data?.contentData?.image_description?.match(/{{[a-zA-Z0-9.]*}}/g) || []
    )?.length;
    const partialOpen = (
      data?.contentData?.image_description?.match(/{[a-zA-Z0-9.]*}}/g) || []
    )?.length;
    const partialclose = (
      data?.contentData?.image_description?.match(/{{[a-zA-Z0-9.]*}/g) || []
    )?.length;
    const partial = (
      data?.contentData?.image_description?.match(/{[a-zA-Z0-9.]*}/g) || []
    )?.length;
    if (
      findBrace === partialOpen &&
      findBrace === partialclose &&
      findBrace === partial
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validateImageTitle = (data) => {
    const findBrace = (
      data?.contentData?.image_title?.match(/{{[a-zA-Z0-9.]*}}/g) || []
    )?.length;
    const partialOpen = (
      data?.contentData?.image_title?.match(/{[a-zA-Z0-9.]*}}/g) || []
    )?.length;
    const partialclose = (
      data?.contentData?.image_title?.match(/{{[a-zA-Z0-9.]*}/g) || []
    )?.length;
    const partial = (data?.contentData?.image_title?.match(/{[a-zA-Z0-9.]*}/g) || [])
      ?.length;
    if (
      findBrace === partialOpen &&
      findBrace === partialclose &&
      findBrace === partial
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validteCurrentDate = (data) => {
    const currentDateTime = new Date(moment.tz(timeZone)).toISOString();
    const startDateISO = data?.activeStartDate
      ? new Date(data?.activeStartDate).toISOString()
      : null;
    const endDateISO = data?.activeEndDate
      ? new Date(data?.activeEndDate).toISOString()
      : null;
    if (startDateISO && new Date(currentDateTime) > new Date(startDateISO)) {
      return { flag: false, name: "Start date" };
    }
    if (endDateISO && new Date(currentDateTime) > new Date(endDateISO)) {
      return { flag: false, name: "End date" };
    }
    return {};
  };

  const validateDates = (data) => {
    const startDate = data?.activeStartDate ? new Date(data?.activeStartDate) : null;
    const endDate = data?.activeEndDate ? new Date(data?.activeEndDate) : null;
    if (!startDate && !endDate) {
      return true;
    }

    if (startDate < endDate) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveBanner = async () => {
    setLoad(true);
    const tempBannerState = { ...bannerState };
    tempBannerState["slug"] =
      bannerState?.contentData?.title + "-" + Math.floor(Math.random() * 40000);

    const isValid = validateSingleJsonFormsData({
      inputSchema: jsonSchema,
      data: bannerState,
    });
    if (!isValid) {
      const tempErr = [];
      tempErr.push("Please set all required rules!");
      setErrMsg(tempErr);
      return;
    }

    const imageTitle = validateImageTitle(tempBannerState);

    if (!imageTitle) {
      const tempErr = [];
      tempErr.push(
        "Please set the image title in correct format Ex: {{user.username}}"
      );
      setErrMsg(tempErr);
      return;
    }

    const imageDesc = validateImageDescription(tempBannerState);

    if (!imageDesc) {
      const tempErr = [];
      tempErr.push(
        "Please set the image description in correct format Ex: {{user.username}}"
      );
      setErrMsg(tempErr);
      return;
    }

    const dates = validateDates(tempBannerState);

    if (!dates) {
      const tempErr = [];
      tempErr.push(
        "Active End Date should be equal or greater than Active Start date.!"
      );
      setErrMsg(tempErr);
      return;
    }
    if (actionStatus !== "edit" && actionStatus !== "copy") {
      const datVal = validteCurrentDate(tempBannerState);

      if (Object.keys(datVal)?.length > 0) {
        if (!datVal.flag) {
          const tempErr = [];
          tempErr.push(`${datVal?.name} should be greater than current date!`);
          setErrMsg(tempErr);
          return;
        }
      }
    }

    const specification = validateRules(tempBannerState);

    if (!specification) {
      return;
    }

    if (
      tempBannerState?.contentData?.title &&
      tempBannerState?.contentData?.description
    ) {
      tempBannerState["title"] = tempBannerState?.contentData?.title;
      tempBannerState["description"] = tempBannerState?.contentData?.description;
    }
    if (
      tempBannerState?.contentData?.action_url === "" ||
      tempBannerState?.contentData?.action_url === null ||
      tempBannerState?.contentData?.action_url === undefined
    ) {
      delete tempBannerState?.contentData?.action_url;
    }
    const params = {
      variables: {
        content: tempBannerState,
      },
    };
    try {
      const save = await saveBannerMutation(params);
      if (save?.data) {
        setSuccessTitle({
          title: tempBannerState?.contentData?.title,
          id: tempBannerState?.id,
        });
        setBannerState(initialState);
        refetch();
        setSuccessMsg("success");
        setTimeZone(null);
        setActionStatus(null);
      }
    } catch (err) {
      const tempErr = [];
      const errorMessage =
        err?.graphQLErrors?.[0]?.message ||
        "Failed to save the banner. Please try again later.";
      tempErr.push(errorMessage);
      setErrMsg(tempErr);
    } finally {
      setLoad(false);
    }
  };

  const handleFilterVariabless = (data) => {
    setFilterVariables(data);
    if (Object.keys(data)?.length === 0) {
      setVariables(initialFilter);
    }
  };

  useEffect(() => {
    setLoad(true);
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables]);

  useEffect(() => {
    const finalFilter = {
      contentDefinitionSlug: "banner-content",
      ...filterVariable,
    };
    setVariables({ filter: finalFilter });
  }, [filterVariable]);

  useEffect(() => {
    if (data && data?.listContent) {
      setBannerList(data?.listContent);
    }
    setLoad(false);
  }, [data]);

  useEffect(() => {
    if (loading === false && load === true) {
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
  }, [loading, load]);

  useEffect(() => {
    if (bannerState?.contentData?.customize_banner === false) {
      if (bannerState?.contentData?.show_button) {
        const tempState = { ...bannerState };
        tempState["contentData"]["show_button"] = false;
        tempState["contentData"]["button_text"] = "";
        tempState["contentData"]["button_color"] = "";
        setBannerState((prev) => ({ ...prev, ...tempState }));
      }
    }
    if (bannerState?.contentData?.show_button) {
      if (bannerState?.contentData?.button_color?.length === 0) {
        const tempState = { ...bannerState };
        tempState["contentData"]["button_color"] = "Light";
        setBannerState((prev) => ({ ...prev, ...tempState }));
      }
    }
  }, [bannerState]);

  useEffect(() => {
    if (
      bannerState?.contentData?.banner_type &&
      bannerState?.contentData?.image_url?.length > 0 &&
      !bannerState?.id
    ) {
      const tempState = { ...bannerState };
      tempState["contentData"]["image_url"] = "";
      setBannerState((prev) => ({ ...prev, ...tempState }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bannerState?.contentData?.banner_type]);

  useEffect(() => {
    if (actionStatus === "copy") {
      const tempState = { ...bannerState };
      const title = `${tempState.title} Copy`;
      const innerState = { ...tempState.contentData };
      tempState.id = null;
      innerState.title = title;
      tempState.title = title;
      tempState.contentData = innerState;
      tempState.contentDefinitionSlug = "banner-content";
      setCopyBannerState(tempState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus]);

  useEffect(() => {
    if (Object.keys(copyBannerState).length > 0) {
      setBannerState(copyBannerState);
      setCopyBannerState({});
    }
  }, [copyBannerState]);

  // Create the context value
  const contextValue = {
    initialState,
    bannerState,
    load,
    bannerList,
    filterVariable,
    jsonSchema,
    uiSchema,
    errMsg,
    successMsg,
    successTitle,
    actionStatus,
    timeZone,
    setTimeZone,
    setActionStatus,
    setSuccessTitle,
    setSuccessMsg,
    setErrMsg,
    setLoad,
    setBannerState,
    handleSaveBanner,
    refetch,
    setFilterVariables,
    handleFilterVariabless,
  };

  // Render the provider with the context value and children
  return (
    <BannerContext.Provider value={contextValue}>{children}</BannerContext.Provider>
  );
};

export { BannerContext, BannerProvider };
