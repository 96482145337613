import { useMutation } from "@apollo/client";
import { useContext } from "react";
import LoaderContext from "../../context/LoaderContext";
import {
  CreateCodeGroupMutation,
  UpdateCodeGroupMutation,
  createDraftCodeGroupMutation,
  updateDraftCodeGroupMutation,
} from "../../graphql/mutations/codeGroupMutations";
import { CODE_GROUP_LIFE_CYCLE_STATUS } from "../../utils/constants";

export function useCodeGroupModal(codeGroupData) {
  const [executeCreateCodeGroup] = useMutation(CreateCodeGroupMutation);
  const [executeCreateDraftCodeGroup] = useMutation(createDraftCodeGroupMutation);
  const [executeUpdateDraftCodeGroup] = useMutation(updateDraftCodeGroupMutation);
  const [executeUpdateCodeGroup] = useMutation(UpdateCodeGroupMutation);

  const { setIsLoading } = useContext(LoaderContext);

  const performDraftCodeGroup = async () => {
    try {
      let codeGroupPayload = {
        administrationStatus: codeGroupData.administrationStatus,
        basicSetup: {
          codeGroupName: codeGroupData.codeGroupName,
          codeActiveStartDate: codeGroupData.codeActiveStartDate,
          codeActiveEndDate: codeGroupData.codeActiveEndDate,
        },
        producerPackaging: {
          brand:
            codeGroupData.brand && codeGroupData.brand.trim() !== ""
              ? codeGroupData.brand
              : null,
          producerCompany: codeGroupData.producerCompany,
          codesPrintedCount: codeGroupData.codesPrintedCount,
          productVolume: codeGroupData.productVolume,
          packageType: codeGroupData.packageType,
          productCapColor: codeGroupData.productCapColor,
        },
        dummyCodes: {
          allowDummyCodes: codeGroupData.allowDummyCodes,
          dummyCodeMessage: codeGroupData.dummyCodeMessage || "",
          dummyCodePercent: codeGroupData.dummyCodePercent || 0,
        },
        codeSpecifications: {
          codeLength: codeGroupData.codeLength,
          usePrefix: codeGroupData.usePrefix,
          prefixVal: codeGroupData.prefixVal || null,
          useSuffix: codeGroupData.useSuffix,
          suffixVal: codeGroupData.suffixVal || null,
          caseSensitive: codeGroupData.caseSensitive,
          excludeCharacters: codeGroupData.excludeCharacters,
          excludeCharacterValues: codeGroupData.excludeCharacterValues || "",
        },
      };
      setIsLoading(true);
      if (codeGroupData.codeGroupId) {
        await executeUpdateDraftCodeGroup({
          variables: {
            codeGroup: codeGroupPayload,
            id: codeGroupData.codeGroupId,
          },
        });
        setIsLoading(false);
        return {
          isSuccess: true,
          id: codeGroupData.codeGroupId,
          message: "Successfully Saved as Draft.",
        };
      }

      const response = await executeCreateDraftCodeGroup({
        variables: {
          codeGroup: codeGroupPayload,
        },
      });
      setIsLoading(false);
      return {
        isSuccess: true,
        codeGroupId: response.data?.createDraftCodeGroup?.codeGroupId,
        message: "Successfully Saved",
      };
    } catch (error) {
      setIsLoading(false);
      return { isSuccess: false, message: error.message };
    }
  };

  const performPublishCodeGroup = async (lifecycleStatus) => {
    try {
      let codeGroupPayload = {
        administrationStatus: codeGroupData.administrationStatus,
        lifecycleStatus,
        basicSetup: {
          codeGroupName: codeGroupData.codeGroupName,
          codeActiveStartDate: codeGroupData.codeActiveStartDate,
          codeActiveEndDate: codeGroupData.codeActiveEndDate,
        },
        producerPackaging: {
          brand:
            codeGroupData.brand && codeGroupData.brand.trim() !== ""
              ? codeGroupData.brand
              : null,
          producerCompany: codeGroupData.producerCompany,
          codesPrintedCount: codeGroupData.codesPrintedCount,
          productVolume: codeGroupData.productVolume,
          packageType: codeGroupData.packageType,
          productCapColor: codeGroupData.productCapColor,
        },
        dummyCodes: {
          allowDummyCodes: codeGroupData.allowDummyCodes,
          dummyCodeMessage: codeGroupData.dummyCodeMessage || "",
          dummyCodePercent: codeGroupData.dummyCodePercent || 0,
        },
        codeSpecifications: {
          codeLength: codeGroupData.codeLength,
          usePrefix: codeGroupData.usePrefix,
          prefixVal: codeGroupData.prefixVal || null,
          useSuffix: codeGroupData.useSuffix,
          suffixVal: codeGroupData.suffixVal || null,
          caseSensitive: codeGroupData.caseSensitive,
          excludeCharacters: codeGroupData.excludeCharacters,
          excludeCharacterValues: codeGroupData.excludeCharacterValues || "",
        },
      };
      if (codeGroupData.codeGroupId) {
        setIsLoading(true);
        await executeUpdateCodeGroup({
          variables: {
            codeGroup: codeGroupPayload,
            id: codeGroupData.codeGroupId,
          },
        });
        setIsLoading(false);
        return {
          isSuccess: true,
          id: codeGroupData.codeGroupId,
          message: "Successfully Updated Code group.",
        };
      }
      setIsLoading(true);
      await executeCreateCodeGroup({
        variables: {
          codeGroup: codeGroupPayload,
        },
      });
      setIsLoading(false);
      return {
        isSuccess: true,
        id: codeGroupData.codeGroupId,
        message: "Successfully Created Code group.",
      };
    } catch (error) {
      setIsLoading(false);
      return { isSuccess: false, message: error.message };
    }
  };

  const validateField = (field, errorMessage) => {
    if (typeof field === "string" && (!field || field?.trim() === "")) {
      return { isValid: false, message: errorMessage };
    }
    if (typeof field === "number" && !field && field !== 0) {
      return { isValid: false, message: errorMessage };
    }
    if (!field) {
      return { isValid: false, message: errorMessage };
    }
    return { isValid: true };
  };

  const validateConditionalField = (condition, field, errorMessage) => {
    if (condition && !field) {
      return { isValid: false, message: errorMessage };
    }
    return { isValid: true };
  };

  const validateNonDraftFields = () => {
    const validations = [
      validateField(codeGroupData.producerCompany, "Producer Company is required"),
      validateField(
        codeGroupData.codesPrintedCount,
        "Codes Printed Count is required"
      ),
      validateField(codeGroupData.productVolume, "Product Volume is required"),
      validateField(codeGroupData.packageType, "Package Type is required"),
      validateField(codeGroupData.productCapColor, "Product Cap Color is required"),
      validateConditionalField(
        codeGroupData.usePrefix,
        codeGroupData.prefixVal,
        "Prefix Value is required"
      ),
      validateConditionalField(
        codeGroupData.useSuffix,
        codeGroupData.suffixVal,
        "Suffix Value is required"
      ),
      validateConditionalField(
        codeGroupData.excludeCharacters,
        codeGroupData.excludeCharacterValues,
        "Exclude Character Values is required"
      ),
      validateConditionalField(
        codeGroupData.allowDummyCodes,
        codeGroupData.dummyCodeMessage,
        "Dummy Code is required"
      ),
      validateConditionalField(
        codeGroupData.allowDummyCodes,
        codeGroupData.dummyCodePercent,
        "Dummy Code Percent is required"
      ),
    ];

    for (const validation of validations) {
      if (!validation.isValid) {
        return validation;
      }
    }

    return { isValid: true };
  };

  const validateCodeGroup = (lifecycleStatus) => {
    const commonValidations = [
      validateField(codeGroupData.codeGroupName, "Code Group Name is required"),
      validateField(
        codeGroupData.codeActiveStartDate,
        "Code Active Start Date is required"
      ),
    ];
    for (const validation of commonValidations) {
      if (!validation.isValid) {
        return validation;
      }
    }
    /* handle non draft validations */
    if (lifecycleStatus !== CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT) {
      return validateNonDraftFields();
    }

    return { isValid: true };
  };
  return {
    validateCodeGroup,
    performDraftCodeGroup,
    performPublishCodeGroup,
  };
}
