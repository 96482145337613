import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { Button } from "pepsico-ds";
import { useState } from "react";
import { validateSingleJsonFormsData } from "../../../utils/validateJsonFormsData";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/id",
      component: "simpleText",
      label: "Preference*",
      rule: {
        effect: "ENABLE",
        condition: {
          scope: "#/properties/id",
          schema: {
            const: "",
          },
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/name",
      component: "textField",
      label: " Title*",
    },

    {
      type: "Control",
      scope: "#/properties/text",
      component: "textarea",
      label: "Description*",
      maxLength: 100,
    },
  ],
};

const jsonSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  title: " Permission Content",
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    id: { type: "string" },
    text: { type: "string" },
  },
  required: ["name", "id", "text"],
};

export default function CreatePermissionsModal({
  handleAddPermission,
  setFormDataPermission,
  showModal,
  setShowModal,
  selectedPermission,
  formDataPermission,
  disabled = false,
}) {
  const [isValidForm, setIsValidForm] = useState(false);
  const onChangeData = (val) => {
    const isValidFormData = validateSingleJsonFormsData({
      inputSchema: jsonSchema,
      data: val,
    });

    setIsValidForm(isValidFormData);
    setFormDataPermission((prevState) => ({
      ...prevState,
      ...val,
    }));
  };

  return (
    <Dialog open={showModal} scroll="paper" maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={11}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
              {selectedPermission?.id ? "Update" : "Add"} Preference
            </h2>
            <JsonFormsWrapper
              readonly={disabled}
              jsonschema={jsonSchema}
              uischema={uiSchema}
              data={formDataPermission}
              onChange={(val) => onChangeData(val)}
            />
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={() => {
                setShowModal(false);
              }}
              className="cursor-pointer"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <div className="mt-5 display-flex justify-content-end align-items-center">
          <Button
            variant="primary"
            className="justify-content-space-around"
            onClick={handleAddPermission}
            disabled={!isValidForm}
          >
            {selectedPermission?.id ? "Update" : "Add"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
