import { useQuery } from "@apollo/client";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { RewardContext } from "../../../context/RewardContext";
import { listPartners } from "../../../graphql/queries/listPartners";

const PartnerSelect = ({ label, selected, required, readonly }) => {
  const {
    rewardState,
    addOptionLoading,
    removeOptionLoading,
    updateRewardState,
    setToast,
  } = useContext(RewardContext);
  const { data: partnersData, loading } = useQuery(listPartners, {
    variables: {
      size: 1000,
      filter: {
        active: true,
      },
    },
    onCompleted: (data) => {
      if (data?.listPartners) {
        const partnersListOptions = data.listPartners.items?.map((partner) => ({
          id: partner.id,
          displayText: partner.name,
        }));
        setPartnersList(partnersListOptions);
      }
    },
    onError: () => {
      setToast({
        open: true,
        type: "error",
        title: "Something went wrong while loading partners.",
        description: "Please try again later.",
      });
    },
    fetchPolicy: "no-cache",
  });
  const [partnersList, setPartnersList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState();

  useEffect(() => {
    if (selected && partnersList.length) {
      const selectedPartner = partnersList.find(
        (partner) => partner.id === selected
      );
      setSelectedPartner([selectedPartner]);
    }
  }, [selected, partnersList]);

  useEffect(() => {
    loading ? addOptionLoading("partners") : removeOptionLoading("partners");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (
      rewardState.partnerId &&
      !(rewardState.partnerName && rewardState.partnerType) &&
      partnersData?.listPartners.items
    ) {
      const partner = partnersData?.listPartners.items?.find(
        (partner) => partner.id === rewardState.partnerId
      );
      updateRewardState({
        ...rewardState,
        partnerName: partner?.name || "",
        partnerType: partner?.type || "",
      });
    }
  }, [partnersData?.listPartners.items, rewardState, updateRewardState]);

  const handleSelectPartner = (val) => {
    if (!val) {
      updateRewardState({});
      return;
    }
    const partnerId = val?.[0]?.id || "";
    const partnerName = val?.[0]?.displayText || "";
    const partnerType =
      partnersData.listPartners.items?.find((partner) => partner.id === partnerId)
        ?.type || "";
    updateRewardState({ ...rewardState, partnerId, partnerName, partnerType });
  };

  const diabledControl = readonly ? "disabled-control" : "";

  return (
    <Dropdown
      isRequired={required}
      childList={partnersList}
      style={{ minWidth: "0px" }}
      className={`zIndexToDropDown ${diabledControl}`}
      label={label}
      selection="single"
      selectedValue={selectedPartner}
      setSelectedValue={handleSelectPartner}
      size="medium"
      placeholder="Select"
      disabled={readonly}
    />
  );
};

export default PartnerSelect;
