import { gql } from "@apollo/client";

export const findCodeById = gql`
  query FindCodeById($code: String!) {
    findCodeById(code: $code) {
      code
      userType
      points
      usedAt
      status
      rewards {
        id
        name
        walletWithdrawalDate
        claimCreatedDate
      }
      codeGroup {
        codeGroupId
        basicSetup {
          codeGroupName
        }
      }
      campaign {
        id
        title
        activeStartDate
        activeEndDate
      }
      profile {
        id
        userInfo {
          firstName
          middleName
          lastName
        }
      }
    }
  }
`;
