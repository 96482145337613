import { gql } from "@apollo/client";

export const getCodeGroups = gql`
  query ListCodeGroups($filter: CodeGroupFilter) {
    listCodeGroups(filter: $filter) {
      codeGroupId
      codesGeneratedCount
      administrationStatus
      codesUsedCount
      lifecycleStatus
      basicSetup {
        codeActiveStartDate
        codeActiveEndDate
        codeGroupName
      }
      producerPackaging {
        codesPrintedCount
      }
    }
  }
`;
