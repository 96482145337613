import { InputItem, Switcher } from "pepsico-ds";
import { useState } from "react";
import CodeInfomation from "./CodeInfomation";
import "./CodeSearch.scss";
import { RelatedCampaign } from "./RelatedCampaign";

const CodeSearchPage = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [codeGroupId, setCodeGroupId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputSearchText = (e) => {
    const value = e.target.value;
    setSearchText(value);

    if (value.length === 10) {
      setHasSearchResults(true);
      setErrorMessage("");
      setSelectedTabIndex(0);
    } else if (value.length > 0) {
      setHasSearchResults(false);
      setErrorMessage("Search text must be exactly 10 characters.");
    } else {
      setHasSearchResults(false);
      setErrorMessage("");
    }
  };

  return (
    <div className="container">
      <div className="code-search-container">
        <h2 className="title">Code Lookup</h2>
        <div className="search-input">
          <InputItem
            placeholder="Type a search keyword..."
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={handleInputSearchText}
            value={searchText}
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <div className="search-result">
          {hasSearchResults ? (
            <div>
              <Switcher
                items={["Code Information", "Related campaigns"]}
                onUpdate={(index) => setSelectedTabIndex(index)}
                selectedItemIndex={selectedTabIndex}
              />
              <div style={{ marginTop: 12 }}>
                {selectedTabIndex === 0 && (
                  <CodeInfomation
                    searchText={searchText}
                    setCodeGroupId={setCodeGroupId}
                  />
                )}
                {selectedTabIndex === 1 && (
                  <RelatedCampaign codeGroupId={codeGroupId} />
                )}
              </div>
            </div>
          ) : (
            !errorMessage && <p>Find the code by going through the information</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodeSearchPage;
