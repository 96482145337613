import { useQuery } from "@apollo/client";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { RewardContext } from "../../../context/RewardContext";
import { listRewardTypes } from "../../../graphql/queries/listRewardTypes";

const RewardTypeSelect = ({ label, selected, required, readonly }) => {
  const {
    rewardState,
    addOptionLoading,
    removeOptionLoading,
    updateRewardState,
    setToast,
  } = useContext(RewardContext);
  const { loading } = useQuery(listRewardTypes, {
    variables: { partnerId: rewardState.partnerId, size: 1000 },
    onCompleted: (data) => {
      if (data?.listRewardType) {
        const typesListOptions = data.listRewardType.items?.map((type) => ({
          id: type.id,
          displayText: type.name,
          schema: type.schemaData,
        }));
        setTypesList(typesListOptions);
        if (selected && typesListOptions.length) {
          const selectedTypeOption = typesListOptions.find(
            (type) => type.id === selected
          );
          setSelectedType([selectedTypeOption ?? { displayText: "" }]);
          updateRewardState({
            ...rewardState,
            type: selectedTypeOption?.displayText || "",
            schema:
              typeof selectedTypeOption?.schema === "object"
                ? selectedTypeOption?.schema
                : "",
          });
        }
      }
    },
    onError: () => {
      setTypesList([]);
      setToast({
        open: true,
        type: "error",
        title: "Something went wrong while loading types.",
        description: "Please try again later.",
      });
    },
    skip: !rewardState?.partnerId,
  });
  const [typesList, setTypesList] = useState([]);
  const [selectedType, setSelectedType] = useState([{ displayText: "" }]);

  useEffect(() => {
    loading ? addOptionLoading("types") : removeOptionLoading("types");
  }, [loading, addOptionLoading, removeOptionLoading]);

  const disabledControl = readonly ? "disabled-control" : "";

  return (
    <Dropdown
      isRequired={required}
      childList={typesList}
      style={{ minWidth: "0px" }}
      className={`zIndexToDropDown ${disabledControl}`}
      label={label}
      selection="single"
      selectedValue={selectedType}
      setSelectedValue={(val) =>
        updateRewardState({
          ...rewardState,
          rewardTypeId: val?.[0]?.id || "",
          type: val?.[0]?.displayText || "",
          schema: typeof val?.[0]?.schema === "object" ? val?.[0]?.schema : "",
        })
      }
      size="medium"
      placeholder="Select"
      disabled={readonly}
    />
  );
};

export default RewardTypeSelect;
