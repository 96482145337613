import { useGrowthBook } from "@growthbook/growthbook-react";
import { useEffect, useRef, useState } from "react";
import { updateGrowthBookAttributes } from "../../utils/growthbook/growthbook.js";
import { oktaEvents } from "../../utils/oktaAuth.js";

const getOktaAccessToken = () => {
  return JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken;
};

const abortReasons = {
  effectCleanup: "effect cleanup",
};

export const GrowthbookWrapper = ({ children }) => {
  const growthBook = useGrowthBook();
  const loadErrorCount = useRef(0);
  const retryTimeoutId = useRef();

  const [accessToken, setAccessToken] = useState(getOktaAccessToken()?.accessToken);

  useEffect(() => {
    const handleChange = (e) => setAccessToken(e.detail?.accessToken?.accessToken);
    // Keeps track of Okta changes on the access token so we can trigger a
    // reload of features when it happens. This allows us to more quickly
    // cover cases in which the loading is failing due to an expired token,
    // instead of waiting for a retry to happen after the token is refreshed.
    document.addEventListener(oktaEvents.tokenChange, handleChange);
    return () => document.removeEventListener(oktaEvents.tokenChange, handleChange);
  }, []);

  useEffect(() => {
    const tokenClaims = getOktaAccessToken()?.claims;
    updateGrowthBookAttributes({
      id: tokenClaims?.gpid,
    });
  }, [accessToken]);

  useEffect(
    function loadFeatures() {
      const abortController = new AbortController();
      loadFeatures(abortController.signal)
        .then(() => log("Features loaded"))
        .catch((reason) => {
          if (reason !== abortReasons.effectCleanup) {
            log(reason);
          }
        });

      return () => {
        if (retryTimeoutId.current) {
          log(`Retry timeout cleared: ${retryTimeoutId.current}`);
          clearTimeout(retryTimeoutId.current);
        }
        abortController.abort(abortReasons.effectCleanup);
        log("Refreshing features due to dependency changes");
      };

      async function loadFeatures(signal) {
        log("Loading features");
        await growthBook.loadFeatures({
          skipCache: true,
          autoRefresh: true,
        });
        signal.throwIfAborted();

        // Assumes the lack of features can be interpreted as an error during
        // loading. growthBook.ready is true even when the loading fails.
        const loadSucceed = Object.keys(growthBook.getFeatures()).length > 0;
        loadErrorCount.current = loadSucceed ? 0 : loadErrorCount.current + 1;
        if (!loadSucceed) {
          return retry(signal);
        }
      }

      function retry(signal) {
        const logRetry = (delay) =>
          log(`Loading failed, retry #${loadErrorCount.current} in ${delay}ms`);

        return new Promise((resolve) => {
          const retriesBeforeExpBackoff = 4;
          if (loadErrorCount.current <= retriesBeforeExpBackoff) {
            const delay = 2000;
            logRetry(delay);
            retryTimeoutId.current = setTimeout(
              () => loadFeatures(signal).then(resolve),
              delay
            );
            return;
          }

          const jitter = 1000 + Math.random() * 1000;
          const exponentialDelay =
            Math.pow(3, loadErrorCount.current - retriesBeforeExpBackoff) * jitter;
          const maxDelay = 300000;
          const delay = Math.min(exponentialDelay, maxDelay);

          logRetry(delay);
          retryTimeoutId.current = setTimeout(
            () => loadFeatures(signal).then(resolve),
            delay
          );
        });
      }
    },
    [
      growthBook,
      // growthBook.loadFeatures() depends on the accessToken, but it's not
      // passed as a parameter, as it's not supported by the SDK. Rather, it is
      // injected into the fetch request using a Proxy declared in
      // public/config/setupGrowthbookFetchProxy.js
      accessToken,
    ]
  );

  return children;
};

function log(message) {
  console.log(`GrowthBook: ${message}`);
}
