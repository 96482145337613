import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Modal, ToastContainer } from "pepsico-ds";
import { useContext, useState } from "react";
import CodeGroupsCard from "../../components/codeGroups/CodeGroupCard";
import CodeGroupSetupCloseModal from "../../components/codeGroups/CodeGroupSetupCloseModal";
import CodeGroupSetupModal from "../../components/codeGroups/CodeGroupSetupModal";
import CodeGroupTable from "../../components/codeGroups/CodeGroupTable";
import CodeGroupViewModal from "../../components/codeGroups/CodeGroupViewModal";
import {
  generateCodeGroupData,
  getInitialCodeGroupData,
  jsonSchema,
} from "../../components/codeGroups/codeGroupUtils";
import {
  ListingComponentContext,
  ListingComponentProvider,
} from "../../components/common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../components/common/listingComponents/ListingComponentWrapper";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import LoaderContext from "../../context/LoaderContext";
import { DELETE_CODE_GROUP } from "../../graphql/mutations/DeleteMutation";
import { getCampaignsTaggedToCodeGroup } from "../../graphql/queries/getCampaignListing";
import { listCodeGroups, loadCodeGroup } from "../../graphql/queries/listCodeGroups";
import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
import "./codeGroups.scss";
import {
  codeGroupFilterJsonSchema,
  codeGroupFilterUiSchema,
  debouceCodeGroupFilterJsonSchema,
  debounceCodeGroupFilterUiSchema,
} from "./mocks";
const CodeGroupsListPage = () => {
  return (
    <ListingComponentProvider
      query={listCodeGroups}
      fetchPolicy="cache-and-network"
      queryKey="listCodeGroups"
      initialFilterData={{ limit: 700 }}
      noPagination={false} // Set to true if you don't want pagination
    >
      <CodeGroupsListContent />
    </ListingComponentProvider>
  );
};

const CodeGroupsListContent = () => {
  const { viewableRecords, viewType, refetch } = useContext(ListingComponentContext);
  const { programTimezone } = useContext(GlobalConfigContext);
  const [codeGroupData, setCodeGroupData] = useState({});
  const [showModal, setShowModal] = useState({
    setupModal: false,
    closeConfirmModal: false,
    viewModal: false,
  });
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [toastData, setToastData] = useState([]);
  const [deleteCodeGroup] = useMutation(DELETE_CODE_GROUP);
  const { setIsLoading } = useContext(LoaderContext);
  const callLoadCodeGroup = async (codeGroupId) => {
    const response = await loadCodeGroupData({ variables: { id: codeGroupId } });
    return response.data;
  };
  const [loadCodeGroupData, { loading: loadCodeGroupLoader }] =
    useLazyQuery(loadCodeGroup);
  const [listCampaigns] = useLazyQuery(getCampaignsTaggedToCodeGroup);
  const downloadFile = async (url, filename) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      window.open(url, "_blank");
      console.error("There was an error downloading the file:", error);
    }
  };
  const exportCode = (exportUrl, exportName) => {
    if (exportUrl) {
      downloadFile(exportUrl, exportName);
    } else {
      setToastData([
        {
          id: 3,
          text: `Export URL not found for the Code Group.`,
          type: "warning",
        },
      ]);
    }
  };
  const deleteCode = (item) => {
    setDeleteModal({ open: true, id: item?.codeGroupId });
  };
  const confirmDelete = async () => {
    setDeleteModal({ open: false, id: null });

    try {
      await deleteCodeGroup({
        variables: {
          id: deleteModal.id,
        },
      });
      refetch();
      setToastData([
        {
          id: 1,
          text: `Codegroup deleted successfully.`,
          type: "success",
        },
      ]);
    } catch (error) {
      console.error(error);
      setToastData([
        {
          id: 1,
          text: `Something went wong.`,
          type: "error",
        },
      ]);
    }
  };

  const checkIfCodeGroupIsLinkedToCampaign = async (codeGroupId, isDraft) => {
    if (isDraft) {
      return false;
    }
    setIsLoading(true);
    const campaignsByCodeGroup = await listCampaigns({
      variables: { filter: { codeGroups: [codeGroupId] } },
    });
    if (campaignsByCodeGroup?.data?.listCampaigns?.length > 0) {
      setIsLoading(false);
      setToastData([
        {
          id: 1,
          text: `This Code Group is associated with one or more campaigns. You cannot edit it.`,
          type: "error",
        },
      ]);
      return true;
    }
    setIsLoading(false);
    return false;
  };

  const handleEditCodeGroup = async (codeGroupId, isDraft) => {
    try {
      const isLinkedToCampaign = await checkIfCodeGroupIsLinkedToCampaign(
        codeGroupId,
        isDraft
      );
      if (isLinkedToCampaign) {
        return;
      }
      setShowModal({ ...showModal, setupModal: true });
      const response = await callLoadCodeGroup(codeGroupId);
      setCodeGroupData(generateCodeGroupData(response.loadCodeGroup));
    } catch {
      setIsLoading(false);
      setShowModal({ ...showModal, setupModal: false });
      setToastData([
        {
          id: 1,
          text: `Something went wrong.`,
          type: "error",
        },
      ]);
    }
  };

  const handleCopyCodeGroup = async (codeGroupId) => {
    try {
      setShowModal({ ...showModal, setupModal: true });
      const response = await callLoadCodeGroup(codeGroupId);
      let copiedData = { ...response.loadCodeGroup };
      delete copiedData.codeGroupId;
      copiedData.basicSetup = {
        ...copiedData.basicSetup,
        codeGroupName: copiedData.basicSetup.codeGroupName + " (Copy)",
      };
      copiedData.lifecycleStatus = CODE_GROUP_LIFE_CYCLE_STATUS.QUEUED;
      copiedData.administrationStatus = CODE_GROUP_ADMINISTRATION_STATUS.ENABLED;
      setCodeGroupData(generateCodeGroupData(copiedData));
    } catch (error) {
      setShowModal({ ...showModal, setupModal: false });
      setToastData([
        {
          id: 1,
          text: `Something went wrong.`,
          type: "error",
        },
      ]);
      console.error(error);
    }
  };

  const handleViewCodeGroup = async (codeGroupId) => {
    try {
      setShowModal({ ...showModal, viewModal: true });
      const response = await callLoadCodeGroup(codeGroupId);
      setCodeGroupData(generateCodeGroupData(response.loadCodeGroup));
    } catch {
      setShowModal({ ...showModal, viewModal: false });
      setToastData([
        {
          id: 1,
          text: `Something went wrong.`,
          type: "error",
        },
      ]);
    }
  };

  const handleEditFromView = async () => {
    try {
      const isLinkedToCampaign = await checkIfCodeGroupIsLinkedToCampaign(
        codeGroupData.codeGroupId,
        codeGroupData.lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT
      );
      if (isLinkedToCampaign) {
        return;
      }
      setShowModal({ ...showModal, viewModal: false, setupModal: true });
    } catch {
      setIsLoading(false);
    }
  };

  const handleActionButtons = (action, group) => {
    switch (action) {
      case "view":
        handleViewCodeGroup(group.codeGroupId);
        break;
      case "edit":
        handleEditCodeGroup(
          group.codeGroupId,
          group.lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT
        );
        break;
      case "copy":
        handleCopyCodeGroup(group.codeGroupId);
        break;
      case "delete":
        deleteCode(group);
        break;
      case "export":
        exportCode(group?.exportUrl, group?.codeGroupId + ".txt");
        break;
      default:
        console.error("Invalid Button Click", action);
    }
  };

  const handleCreateCodeGroup = () => {
    setShowModal({ ...showModal, setupModal: true });
    setCodeGroupData(getInitialCodeGroupData(jsonSchema));
  };

  return (
    <div className="px-3 pt-3">
      {toastData && (
        <ToastContainer
          className="code-group-listing-toast"
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      <div className="container-card">
        <div className="codegroups-page-content">
          <div className="codegroups-header">
            <h2>Code Groups</h2>
            <Button size="medium" iconTrailing="add" onClick={handleCreateCodeGroup}>
              Create Code Group
            </Button>
          </div>
          <ListingComponentWrapper
            customStyles={{ flex: 1 }}
            filterClasses="codegroups-filter-styles"
            filterConfig={{
              withDebounce: {
                jsonSchema: debouceCodeGroupFilterJsonSchema,
                uiSchema: debounceCodeGroupFilterUiSchema,
              },
              withoutDebounce: {
                jsonSchema: codeGroupFilterJsonSchema,
                uiSchema: codeGroupFilterUiSchema,
              },
            }}
          >
            {viewType === "grid" ? (
              <div className="codegroups-grid-container">
                {viewableRecords.map((group, index) => (
                  <div className="codegroups-grid-item" key={index}>
                    <CodeGroupsCard
                      key={index}
                      group={group}
                      currentProgramTimezone={programTimezone}
                      handleActionButtons={handleActionButtons}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <CodeGroupTable
                codeGroupData={viewableRecords}
                handleActionButtons={handleActionButtons}
              />
            )}
          </ListingComponentWrapper>
        </div>
        {showModal.setupModal && (
          <CodeGroupSetupModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            showModal={showModal}
            setShowModal={setShowModal}
            codeGroupData={codeGroupData}
            refetchListing={refetch}
            setCodeGroupData={setCodeGroupData}
            setParentToastData={setToastData}
          />
        )}
        {showModal.closeConfirmModal && (
          <CodeGroupSetupCloseModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            showModal={showModal}
            setShowModal={setShowModal}
            codeGroupData={codeGroupData}
            refetchListing={refetch}
            setCodeGroupData={setCodeGroupData}
            setParentToastData={setToastData}
          />
        )}
        {showModal.viewModal && (
          <CodeGroupViewModal
            loadCodeGroupLoader={loadCodeGroupLoader}
            codeGroupData={codeGroupData}
            showModal={showModal}
            setCodeGroupData={setCodeGroupData}
            setShowModal={setShowModal}
            handleEditFromView={handleEditFromView}
          />
        )}
        {deleteModal.open && (
          <Modal
            data-testid="delete-codegroup-modal"
            showModal={deleteModal.open}
            title="Delete this Codegroup?"
            text="Are you sure you want to delete this code group? This action cannot be undone."
            className="content-delete-modal"
            onCloseModal={() => setDeleteModal({ open: false, id: null })}
            primaryButtonProps={{
              size: "medium",
              text: "Yes, Delete",
              variant: "primary",
              onClick: confirmDelete,
            }}
            secondaryButtonProps={{
              size: "medium",
              text: "Cancel",
              variant: "secondary",
              onClick: () => setDeleteModal({ open: false, id: null }),
            }}
            showTertiaryButton={false}
            showLink={false}
          />
        )}
      </div>
    </div>
  );
};

export default CodeGroupsListPage;
