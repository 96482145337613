import SummaryCard from "./SummaryCard";

let languageMap = new Intl.DisplayNames(["en"], { type: "language" });
const languageShortName = (language) => {
  return languageMap.of(language.split("-")[0]);
};

const LocalizationList = ({ data, editSectionHandler }) => {
  return (
    <SummaryCard title="Localization Content" handleEditSection={editSectionHandler}>
      {Object.entries(data.l10n).map(([lang, content]) => {
        const language = languageShortName(lang) || lang; // Fallback to lang if mapping not found

        return (
          <div key={lang} className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">{language} Title:</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">{content?.title}</span>
            </div>
            {content?.description && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} Description:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.description}</span>
                </div>
              </>
            )}
            {content?.link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} link:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.link}</span>
                </div>
              </>
            )}
            {content?.redirect_link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} redirect link:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.redirect_link}</span>
                </div>
              </>
            )}
            {content?.survey_link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} survey link:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.survey_link}</span>
                </div>
              </>
            )}
            {content?.terms_and_conditions && (
              <>
                <div className="field-pair">
                  <span className="field-label">
                    {language} terms and conditions link:
                  </span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    <a href={content.terms_and_conditions} target="_blank">
                      {content.terms_and_conditions}
                    </a>
                  </span>
                </div>
              </>
            )}

            {content?.input_label && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} Input Label:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.input_label}</span>
                </div>
              </>
            )}

            {content?.input_placeholder && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} Input Placeholder:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.input_placeholder}</span>
                </div>
              </>
            )}

            {content?.cta_label && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} CTA Label:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.cta_label}</span>
                </div>
              </>
            )}
            {content?.terms_and_conditions_text && (
              <>
                <div className="field-pair">
                  <span className="field-label">
                    {language} Terms & Conditions Text:
                  </span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    {content.terms_and_conditions_text}
                  </span>
                </div>
              </>
            )}
            {content?.terms_and_conditions_link && (
              <>
                <div className="field-pair">
                  <span className="field-label">
                    {language} Terms & Conditions URL:
                  </span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    <a href={content.terms_and_conditions_link} target="_blank">
                      {content.terms_and_conditions_link}
                    </a>
                  </span>
                </div>
              </>
            )}
            {content?.instructions_text && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} Instructions Text:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.instructions_text}</span>
                </div>
              </>
            )}
            {content?.instructions_link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} Instructions URL:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    <a href={content.instructions_link} target="_blank">
                      {content.instructions_link}
                    </a>
                  </span>
                </div>
              </>
            )}

            <div className="field-pair">
              <span className="field-label">{language} Image:</span>
            </div>
            <div className="field-pair-two-column">
              <img
                src={content.campaign_image_url}
                alt={content.title}
                style={{ width: "120px", borderRadius: "8px" }}
              />
            </div>
          </div>
        );
      })}
    </SummaryCard>
  );
};

export default LocalizationList;
