import { useCallback, useState } from "react";
import { useCreatePartnerCodeGroupMutation } from "../../Hooks/useCreatePartnerGroupMutation";
import PartnerCodeGroupFormModal from "../PartnerCodeGroupForm/PartnerCodeGroupForm";

function CreatePartnerCodeGroupModel({
  createPartnerCodeGroupModalOpen,
  closeCreatePartnerCodeGroupModal,
  setToast,
  refetch,
}) {
  const [partnerCodeGroupData, setPartnerCodeGroupData] = useState({});
  const [createPartnerCodeGroupMutation, { loading }] =
    useCreatePartnerCodeGroupMutation();

  const handleCreatePartnerCodeGroup = useCallback(() => {
    const resetForm = () => {
      setPartnerCodeGroupData({});
      closeCreatePartnerCodeGroupModal();
    };

    const onSuccess = () => {
      setToast({
        open: true,
        type: "success",
        title: `Code Group ${partnerCodeGroupData?.name} Created!`,
        description: "You have just published a partner Group!",
      });
      resetForm();
      refetch();
    };

    const onFailure = (error) => {
      setToast({
        open: true,
        type: "error",
        title: `Error creating Code Group: ${partnerCodeGroupData?.name}`,
        description: error?.message || "",
      });
      resetForm();
    };

    createPartnerCodeGroupMutation(onSuccess, onFailure, partnerCodeGroupData);
  }, [
    closeCreatePartnerCodeGroupModal,
    createPartnerCodeGroupMutation,
    partnerCodeGroupData,
    refetch,
    setToast,
  ]);

  return (
    <PartnerCodeGroupFormModal
      isOpen={createPartnerCodeGroupModalOpen}
      toggleCreatePartnerCodeModal={closeCreatePartnerCodeGroupModal}
      closeModal={closeCreatePartnerCodeGroupModal}
      partnerCodeGroupData={partnerCodeGroupData}
      setPartnerCodeGroupData={setPartnerCodeGroupData}
      handleMutation={handleCreatePartnerCodeGroup}
      loading={loading}
      title="Create"
      isEditMode={false}
    />
  );
}

export default CreatePartnerCodeGroupModel;
