import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import "../../common/DatePickerMaterialUI/DatePickerMaterialUI.scss";

moment.locale("en");

export default function DatePickerMaterialUIField(props) {
  const {
    value,
    updateValue,
    label,
    visible,
    placeholder,
    disabled,
    timezone,
    readOnly,
  } = props;

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString();
      updateValue(formattedDate);
    } else {
      updateValue(null);
    }
  };

  return visible ? (
    <>
      <div className="date-picker-material-ui-wrap">
        {label && <label htmlFor={`datePicker-${label}`}>{label}</label>}
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
          adapterLocale="en"
        >
          <DatePicker
            className="customDatePickerStyles"
            value={value ? moment.tz(value, timezone) : null}
            onChange={handleDateChange}
            // minDate={moment.tz(new Date(), timezone)}
            label={placeholder}
            disabled={disabled}
            readOnly={readOnly}
          />
        </LocalizationProvider>
      </div>
    </>
  ) : null;
}
