import { useQuery } from "@apollo/client";
import { LoadingSpinner } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { getCampaignListing } from "../../../graphql/queries/getCampaignListing";
import { getDateWithFormat, removeEmptyValues } from "../../../utils/commons";
import CampaignFilter from "./CampaignFilter";

export const RelatedCampaign = ({ codeGroupId }) => {
  const [relatedCampaigns, setRelatedCampaigns] = useState([]);
  const [filters, setFilters] = useState({ codeGroups: [codeGroupId] });
  const { programTimezone } = useContext(GlobalConfigContext);

  const {
    data: campaignData,
    refetch: refetchCampaign,
    loading,
  } = useQuery(getCampaignListing, {
    skip: !codeGroupId,
    variables: { filter: filters },
    onCompleted: () => {
      setRelatedCampaigns(campaignData?.listCampaigns || []);
    },
    onError: (err) => {
      console.error("Error fetching campaigns:", err);
    },
  });

  // Handle filter changes
  const handleFilterChange = (selectedFilter) => {
    const updatedFilters = removeEmptyValues(selectedFilter);
    setFilters(updatedFilters);
    refetchCampaign({ filter: updatedFilters }); // Refetch with new filters
  };

  const convertToTitleCase = (str) => {
    return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
  };

  useEffect(() => {
    if (codeGroupId?.length > 0) {
      refetchCampaign();
    }
  }, [codeGroupId, refetchCampaign]);

  return (
    <div className="list-container">
      <div>
        <CampaignFilter handleFilterChange={handleFilterChange} filters={filters} />
      </div>
      {loading ? (
        <LoadingSpinner
          size={{ height: "30px", width: "30px" }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table className="list-table">
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Campaign Start Date</th>
              <th>Campaign End Date</th>
              <th>Campaign Type</th>
            </tr>
          </thead>
          <tbody>
            {relatedCampaigns.length > 0 ? (
              relatedCampaigns.map((campaign) => (
                <tr key={campaign.id}>
                  <td>{campaign?.title}</td>
                  <td>
                    {getDateWithFormat(campaign?.activeStartDate, programTimezone)}
                  </td>
                  <td>
                    {getDateWithFormat(campaign?.activeEndDate, programTimezone)}
                  </td>
                  <td>{convertToTitleCase(campaign?.type)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No related campaigns found</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};
