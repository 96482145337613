import { createAjv } from "@jsonforms/core";
import Ajv from "ajv";
import { jwtDecode } from "jwt-decode";
import _ from "lodash";
import moment from "moment-timezone";

/**
 * The locale used for localization.
 * @type {string}
 */
const locale = "en-US";

// Utility function to remove \n, \r, \t from a string
export const removeNewLine = (str) => str.replace(/(\r\n|\n|\r|\t|\\"|\\\\)/gm, " ");

// Utility function to remove specific characters from a string
export const removeChar = (str, char, replace = "") =>
  str.replace(new RegExp(char, "g"), replace);

// Utility function to replace characters in a string
export const replaceChar = (str, char, replace) => str.replace(char, replace);

// Function to validate if a string matches a regular expression
export const regExValidator = (regEx, string) => regEx.test(string);

// Convert an array of strings into a comma-separated string
export const commaSpaceSeparatedString = (array) =>
  Array.isArray(array) ? array.join(", ") : "";

// Check if two objects are deeply equal
export const isDeepEqual = (object1, object2) => {
  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  return objKeys1.every((key) => {
    const value1 = object1[key];
    const value2 = object2[key];
    const bothObjects = isObject(value1) && isObject(value2);

    return bothObjects ? isDeepEqual(value1, value2) : value1 === value2;
  });
};

// Check if a value is an object
export const isObject = (value) => value !== null && typeof value === "object";

// Function to parse YouTube video ID from a URL
export const youtubeParser = (url) => {
  const shortsRegex = /^https:\/\/www\.youtube\.com\/shorts\/([^?]+)(?:[?]|$)/;
  let match = url.match(shortsRegex);

  if (match) return match[1];

  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  match = url.match(regExp);

  return match && match[7]?.length === 11 ? match[7] : false;
};

// Function to safely parse a JSON string
export const getJsonIfValid = (stringifiedJson = "") => {
  try {
    return JSON.parse(stringifiedJson);
  } catch (e) {
    console.warn(e);
    return false;
  }
};

// format segment attributes
export const formatSegmentKeys = (key) => {
  return key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
};

// Convert the casing of a string based on provided parameters
export const convertKeyCasing = (key, splitBy, joinBy, casing = "UPPER") => {
  if (!key) return "";
  const formattedKey = key.split(splitBy).join(joinBy);
  return casing === "UPPER"
    ? formattedKey.toUpperCase()
    : formattedKey.toLowerCase();
};

// Validate JSON schema with data
const isValueInvalid = (value) =>
  value === "" || value === null || value === undefined;

const isInvalidInteger = (property, value) => {
  return (
    property.type === "integer" &&
    property.minimum &&
    (value < property.minimum || !Number.isInteger(value))
  );
};

const isInvalidString = (property, value) => {
  if (property.type !== "string") return false;
  if (property.minLength && value.length < property.minLength) return true;
  if (property.maxLength && value.length > property.maxLength) return true;
  return false;
};

const isInvalidObject = (property, value) => {
  return property.type === "object" && !validateSchemawithData(property, value);
};

const isInvalidUriString = (value) => {
  const ajvSchema = {
    type: "object",
    properties: {
      url: {
        type: "string",
        format: "uri",
      },
    },
    required: ["url"],
  };

  const ajv = createAjv();
  const result = ajv.validate(ajvSchema, { url: value });
  return !result;
};

const isInvalidArray = (property, value) => {
  if (property.type !== "array") return false; // Return false if not an array
  if (!Array.isArray(value) || value.length < property.minItems) return true; // Invalid array case
  if (property.items?.type === "object") {
    return !value.every((item) => validateSchemawithData(property.items, item)); // Negate the result to match invalid case
  }
  return !value.every((item) => {
    if (isValueInvalid(item)) {
      return false;
    }
    if (property.format === "uri") {
      if (isInvalidUriString(item)) {
        return false;
      }
    }
    return true;
  });

  // return !value.every((item) => !isValueInvalid(item)); // Return false if any item is invalid
};

const validatePropertyType = (property, value) => {
  if (isInvalidInteger(property, value)) return false;
  if (isInvalidString(property, value)) return false;
  if (isInvalidObject(property, value)) return false;
  if (isInvalidArray(property, value)) return false;

  return true;
};

export const validateSchemawithData = (schema, data) => {
  if (schema.type !== "object") return true;

  return (schema.required || []).every((key) => {
    const value = data[key];
    const property = schema.properties[key];

    if (!Object.prototype.hasOwnProperty.call(data, key) || isValueInvalid(value)) {
      return false;
    }

    return validatePropertyType(property, value);
  });
};

// Validate JSON schema using AJV
export const validateSchemaWithAJV = (schema, data) => {
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  return validate(data);
};

// Decode Okta access token stored in localStorage
export const decodeOktaAccessToken = () => {
  try {
    const oktaTokenStorage = localStorage.getItem("okta-token-storage");
    if (!oktaTokenStorage) return null;

    const token = JSON.parse(oktaTokenStorage).accessToken?.accessToken || "";
    return token ? jwtDecode(token) : null;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

/* returns the updated UIschema based on target scope */
export const updateUiSchema = (schema, targetScopes) => {
  if (Array.isArray(schema.elements)) {
    schema.elements = schema.elements.map((element) =>
      updateUiSchema(element, targetScopes)
    );
  }

  if (Object.prototype.hasOwnProperty.call(targetScopes, schema.scope)) {
    return { ...schema, ...targetScopes[schema.scope] };
  }

  return schema;
};

/*
 * @deprecated - Use `convertDateToLocaleTz` instead for better timezone handling
 */
export const getDateWithFormat = (data, currentProgramTimezone, dateFormat) => {
  const timezone =
    currentProgramTimezone ||
    (window.REACT_APP_PROGRAM === "kaz" ? "Europe/Istanbul" : "America/Chicago");
  return moment
    .tz(new Date(data), timezone)
    .format(dateFormat || "DD/MM/YYYY hh:mm a");
};

/**
 * Returns the abbreviation for a given timezone.
 *
 * @param {string} timezone - The timezone identifier (e.g., "Europe/Istanbul", "America/Chicago").
 * @returns {string} The abbreviation for the given timezone (e.g., "TRT", "CST" or "CDT").
 *
 */
export const timezoneAbbreviationHelper = (timezone) => {
  // Change to switch statement for more timezone abbreviations
  switch (timezone) {
    case "Europe/Istanbul":
      return "TRT";
    case "America/Sao_Paulo":
      return "BRT";
    default:
      return moment().tz(timezone).format("z");
  }
};

// Convert date string to a formatted date string in the user's local timezone
export const convertDateToLocaleTz = (
  dateString,
  tz = "America/Chicago",
  includeTzName = true
) => {
  try {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      ...(includeTzName && { timeZoneName: "short" }),
      timeZone: tz,
    };

    return new Intl.DateTimeFormat(locale, options).format(date).replace(/,/g, "");
  } catch (e) {
    console.warn(e);
    return dateString;
  }
};

// Capitalize the first letter of a string
export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

// Format start and end dates with a hyphen separator
export const formatDatesWithHyphen = (start, end) => {
  if (!start && !end) return "-";

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return `${formatDate(start)} - ${formatDate(end)}`;
};

// Validate email domains in an array of domains
export const validateDomains = (domains) => {
  if (!domains || !domains.length) return false;
  const pattern = "@\\w+(\\.\\w+)+";
  const regEx = new RegExp(pattern);
  const validDomains = domains.every((domain) => regEx.test(domain));
  return validDomains;
};

export const returnPlaceHolderIfEmpty = (actualValue, placeHolder) =>
  actualValue !== null && actualValue !== undefined && actualValue !== ""
    ? actualValue
    : placeHolder;

export const randomString = (preffix) =>
  `${preffix}_${Date.now() + Math.floor(Math.random() * 1000)}`;

export const removeEmptyValues = (obj) => {
  return _.omitBy(obj, (value) => {
    if (_.isNumber(value) || _.isBoolean(value)) {
      return false;
    }
    return _.isEmpty(value);
  });
};

export const convertToTitleCase = (string) => {
  if (!string) {
    return "";
  }
  return string.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

// Utility function to check if an object is empty
export const isEmptyObject = (obj) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;
