export const partnersCodeGroupActionButtons = (
  status,
  handleActionButtons,
  partnerCodeGroupData
) => {
  const actionButtons = [
    {
      icon: "visibility",
      onClick: () => handleActionButtons("view", partnerCodeGroupData),
    },
    {
      icon: "content_copy",
      onClick: () => handleActionButtons("copy", partnerCodeGroupData),
    },
    {
      icon: "edit",
      onClick: () => handleActionButtons("edit", partnerCodeGroupData),
    },
  ];
  const deletableStatuses = ["INACTIVE", "EXPIRED"];

  deletableStatuses.includes(status) &&
    actionButtons.push({
      icon: "delete",
      onClick: () => handleActionButtons("delete", partnerCodeGroupData),
    });
  return actionButtons;
};
