import { GrowthBook, setPolyfills } from "@growthbook/growthbook-react";
import "eventsource/example/eventsource-polyfill";

export const GrowthBookEventSource = new Proxy(window.EventSourcePolyfill, {
  construct(target, [url, eventSourceInitDict]) {
    const accessToken = JSON.parse(window.localStorage.getItem("okta-token-storage"))
      ?.accessToken?.accessToken;
    if (!accessToken) {
      console.warn(
        `Access token was not found. This is required for our self-hosted GrowthBook platform`
      );
    }

    return new target(url, {
      ...eventSourceInitDict,
      headers: {
        ...eventSourceInitDict?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
});

setPolyfills({
  EventSource: GrowthBookEventSource,
});

export const growthbook = new GrowthBook({
  apiHost: window.REACT_APP_GROWTHBOOK_HOST,
  clientKey: window.REACT_APP_GROWTHBOOK_CLIENT_KEY,
});

export const updateGrowthBookAttributes = (attributes) =>
  growthbook.setAttributes({
    ...growthbook.getAttributes(),
    ...attributes,
  });

export function hasFeatures() {
  return Object.keys(growthbook.getFeatures()).length > 0;
}
