import { Button } from "pepsico-ds";
import JsonFormsWrapper from "../../../components/jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./CampaignFilter.scss";
import { useFilterCampaigns } from "./filterSchema";

const CampaignFilter = ({ filters = {}, handleFilterChange }) => {
  const { relatedCampaignFilterInputSchema, relatedCampaignFilterUiSchema } =
    useFilterCampaigns();

  const clearFilter = () => {
    handleFilterChange({ codeGroups: filters.codeGroups });
  };

  return (
    <div
      className="filter-container"
      style={{ display: "flex", alignItems: "center" }}
    >
      <JsonFormsWrapper
        jsonschema={relatedCampaignFilterInputSchema}
        uischema={relatedCampaignFilterUiSchema}
        data={filters}
        onChange={handleFilterChange}
      />
      <span style={{ marginLeft: 24 }}>
        <Button size="large" text="Clear" variant="tertiary" onClick={clearFilter} />
      </span>
    </div>
  );
};

export default CampaignFilter;
