import { Icon, SideBar } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { useHasAccessPermission } from "../../hooks/useHasAccessPermission";
import "./sidebar.scss";

import kzConfig from "../../config/config-kz.json";
import ptrConfig from "../../config/config-ptr.json";

const CustomSideBar = () => {
  const {
    globalState: { isPanelOpen },
    updateSidebarPanelState,
  } = useContext(GlobalConfigContext);

  const [navigationConfig, setNavigationConfig] = useState({});
  const hasAccess = useHasAccessPermission();

  useEffect(() => {
    if (window !== undefined && window.REACT_APP_PROGRAM === "ptr") {
      setNavigationConfig(ptrConfig.navigation);
    } else {
      setNavigationConfig(kzConfig.navigation);
    }
  }, []);

  return (
    <div
      className="display-flex flex-direction-column mr-1 hideScrollBar"
      style={{
        boxShadow: "4px 0px 24px -2px rgba(0, 92, 188, 0.08)",
        maxWidth: "300px",
        overflowY: "auto",
      }}
    >
      {!isPanelOpen && (
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => updateSidebarPanelState(true)}
          alt="menu"
          className="$spacing-pl05 $spacing-pr04 $spacing-py03"
          icon="menu"
        />
      )}
      <SideBar
        className={`sidebarStyles ${isPanelOpen ? "sidebarStyles-open" : "sidebarStyles-close"}`}
        items={Object.entries(navigationConfig)
          .filter(([, value]) => hasAccess(value?.roles) && !value.hideFromNav)
          .map(([, value]) => {
            const sublinks = value.children?.map((child) => {
              return {
                label: (
                  <NavItem
                    key={`${JSON.stringify(child)}`}
                    title={child.title}
                    navigateTo={child.navigateTo}
                  />
                ),
              };
            });

            return {
              label: value.title,
              icon: value.icon,
              url: value.navigateTo,
              sublinks: sublinks?.length ? sublinks : undefined,
            };
          })}
      />
    </div>
  );
};

const NavItem = ({ title, navigateTo }) => {
  return (
    <Link
      to={navigateTo}
      style={{
        height: "100%",
        textDecoration: "none",
        whiteSpace: "nowrap",
        width: "100%",
      }}
    >
      {title}
    </Link>
  );
};
export default CustomSideBar;
