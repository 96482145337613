import { useQuery } from "@apollo/client";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { LoadProgram } from "../graphql/queries/loadProgram";
import { programConfigDuplicateJson } from "../mocks/program-config-duplicate";
import { decodeOktaAccessToken } from "../utils/commons";
import LoaderContext from "./LoaderContext";

// Create the GlobalConfigContext
const GlobalConfigContext = createContext();

// Create the GlobalConfigProvider component
const GlobalConfigProvider = ({ children }) => {
  // Define the initial state
  const initialState = {
    navigationSelected: {
      navIndex: 1,
      subNavIndex: 0,
    },
    programConfig: programConfigDuplicateJson.loadProgram || {},
    isPanelOpen: true,
    adminDetails: null,
  };
  // Create the state using useState
  const [globalState, setGlobalState] = useState({ ...initialState });
  const { data, loading, refetch: refetchProgramConfig } = useQuery(LoadProgram);
  const { oktaAuth, authState } = useOktaAuth();
  const { setIsLoading } = useContext(LoaderContext);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading, setIsLoading]);

  useEffect(() => {
    if (!authState) {
      return;
    }
    if (!authState.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState]);

  useEffect(() => {
    if (authState?.isAuthenticated && !globalState.adminDetails) {
      const adminDetails = decodeOktaAccessToken();
      setGlobalState((prev) => ({
        ...prev,
        adminDetails,
      }));
    }
  }, [authState, globalState.adminDetails]);

  useEffect(() => {
    setGlobalState((globalState) => ({
      ...globalState,
      programConfig: data?.loadProgram,
    }));
  }, [data]);

  // Define any functions or methods to update the state here
  const updateSidebarPanelState = (isOpen) => {
    setGlobalState((prev) => ({
      ...prev,
      isPanelOpen: isOpen,
    }));
  };

  const updateNavigationState = (newIndexState) => {
    setGlobalState((prev) => ({
      ...prev,
      navigationSelected: { ...prev.navigationSelected, ...newIndexState },
    }));
  };

  const updateProgramConfigurations = (newProgramConfigurationsData) => {
    setGlobalState((prev) => ({
      ...prev,
      programConfig: {
        ...prev.programConfig,
        configurations: newProgramConfigurationsData,
      },
    }));
  };

  const updateWidgetsData = (newWidgetsData) => {
    setGlobalState((prev) => ({
      ...prev,
      widgetsData: newWidgetsData,
    }));
  };

  const campaignsConfiguration = useMemo(() => {
    const campaigns = globalState?.programConfig?.campaigns?.filter(
      (campaign) => campaign?.value?.enabled
    );
    return campaigns || [];
    // return data?.loadProgram?.campaigns || {};
  }, [globalState?.programConfig?.campaigns]);

  const campaignGroups = useMemo(() => {
    return globalState?.programConfig?.campaignsGroups || [];
  }, [globalState?.programConfig?.campaignsGroups]);

  const widgetsData = useMemo(() => {
    return globalState?.programConfig?.widgets || [];
  }, [globalState?.programConfig?.widgets]);

  const widgetDefinitions = useMemo(() => {
    return globalState?.programConfig?.widgetDefinitions || [];
  }, [globalState?.programConfig?.widgetDefinitions]);

  const tagsData = useMemo(() => {
    return globalState?.programConfig?.tags || [];
  }, [globalState?.programConfig?.tags]);

  const categoryData = useMemo(() => {
    return globalState?.programConfig?.categories || [];
  }, [globalState?.programConfig?.categories]);

  const programSlug = useMemo(() => {
    return globalState?.programConfig?.slug || "";
  }, [globalState?.programConfig?.slug]);

  const programConfigurations = useMemo(() => {
    return globalState?.programConfig?.configurations || [];
  }, [globalState?.programConfig?.configurations]);

  const programTimezone = globalState?.programConfig?.localization?.programTimezone;

  // Render the provider with the context value and children
  return (
    <GlobalConfigContext.Provider
      value={{
        globalState,
        campaignsConfiguration,
        campaignGroups,
        tagsData,
        categoryData,
        programSlug,
        setGlobalState,
        updateSidebarPanelState,
        updateNavigationState,
        widgetsData,
        updateWidgetsData,
        widgetDefinitions,
        programTimezone,
        refetchProgramConfig,
        programConfigurations,
        updateProgramConfigurations,
      }}
    >
      {children}
    </GlobalConfigContext.Provider>
  );
};

export { GlobalConfigContext, GlobalConfigProvider };
