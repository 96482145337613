import "./table.scss";

const Table = ({ headerData, rowData, className }) => {
  return (
    <table className={`common-table ${className || ""}`} data-testid="table">
      <thead>
        <tr>
          {headerData.map((header) => (
            <th key={header.columnKey}>{header.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rowData.length ? (
          rowData.map((row, index) => (
            <tr key={index}>
              {headerData.map((header) => (
                <td key={header.columnKey}>{row[header.columnKey]}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={headerData.length}>No items found.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
