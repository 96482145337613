import { useQuery } from "@apollo/client";
import { Icon, LoadingSpinner, Tag } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { findCodeById } from "../../../graphql/queries/findCodeById";
import { getDateWithFormat } from "../../../utils/commons";

const CodeInformation = ({ searchText, setCodeGroupId }) => {
  const [codeInformation, setCodeInformation] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const { programTimezone } = useContext(GlobalConfigContext);
  const [error, setError] = useState(null);

  const {
    data: codeGroupData,
    refetch: refetchCodeGroup,
    loading,
  } = useQuery(findCodeById, {
    variables: { code: searchText },
    onCompleted: () => {
      setError(null);
      setCodeInformation(codeGroupData?.findCodeById);
      setCodeGroupId(codeGroupData?.findCodeById?.codeGroup?.codeGroupId);
    },
    onError: (err) => {
      setCodeGroupId("");
      setError(`${err?.graphQLErrors?.[0]?.message || err?.message}`);
      console.error("Error fetching code group data:", err);
    },
  });

  useEffect(() => {
    setCodeGroupId("");
    refetchCodeGroup();
  }, [searchText, refetchCodeGroup, setCodeGroupId]);

  const toggleExpand = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const renderCodeInfoRow = () => {
    if (error) {
      return (
        <tr>
          <td colSpan={8}>{error}</td>
        </tr>
      );
    }

    if (!codeInformation?.status) {
      return (
        <tr>
          <td colSpan={8}>No results found</td>
        </tr>
      );
    }

    return (
      <tr onClick={toggleExpand}>
        <td>
          <Icon alt="expand/collapse" icon="arrow_right" size="medium" />
        </td>
        <td>
          <Tag
            color={codeInformation?.status === "CLAIMED" ? "Green-150" : "Cyan-500"}
            text={codeInformation?.status === "CLAIMED" ? "Used" : "Unused"}
            isCopyable={false}
          />
        </td>
        <td>{codeInformation?.codeGroup?.basicSetup?.codeGroupName}</td>
        <td>{codeInformation?.campaign?.title}</td>
        <td>
          {getDateWithFormat(
            codeInformation?.campaign?.activeStartDate,
            programTimezone
          )}
        </td>
        <td>
          {getDateWithFormat(
            codeInformation?.campaign?.activeEndDate,
            programTimezone
          )}
        </td>
        <td>
          {codeInformation?.profile?.userInfo ? (
            <span>
              {codeInformation?.profile?.userInfo?.firstName}{" "}
              {codeInformation?.profile?.userInfo?.middleName}{" "}
              {codeInformation?.profile?.userInfo?.lastName}
            </span>
          ) : (
            <span>-</span>
          )}
        </td>
        <td>{codeInformation?.userType}</td>
      </tr>
    );
  };

  const renderRewardsTable = () => {
    if (!isExpanded || !codeInformation?.rewards?.length > 0) {
      return null;
    }
    return (
      <tr>
        <td colSpan="8">
          <table style={{ width: "95%", marginLeft: 40 }}>
            <thead>
              <tr>
                <th>Reward</th>
                <th>Win Date</th>
                <th>Used Date</th>
              </tr>
            </thead>
            <tbody>
              {codeInformation?.rewards?.map((reward) => (
                <tr key={reward?.id}>
                  <td>{reward?.name}</td>
                  <td>
                    {getDateWithFormat(reward?.claimCreatedDate, programTimezone)}
                  </td>
                  <td>
                    {getDateWithFormat(
                      reward?.walletWithdrawalDate,
                      programTimezone
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  return (
    <div className="list-container">
      {loading ? (
        <LoadingSpinner
          size={{ height: "30px", width: "30px" }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table className="list-table">
          <thead>
            <tr>
              <th></th>
              <th>Status</th>
              <th>Code Group</th>
              <th>Campaign</th>
              <th>Campaign Start Date</th>
              <th>Campaign End Date</th>
              <th>User</th>
              <th>User Type</th>
            </tr>
          </thead>
          <tbody>
            {renderCodeInfoRow()}
            {renderRewardsTable()}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CodeInformation;
