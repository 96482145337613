import { useQuery } from "@apollo/client";
import { Button } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import TermAndConditionModal from "../../../../components/campaign/TermAndConditionModal";
import { GlobalConfigContext } from "../../../../context/GlobalConfigContext";
import { matchKeywordGroupsList } from "../../../../graphql/queries/listKeywordGroups";
import { listRewardCategories } from "../../../../graphql/queries/listRewardCategories";
import { listSegments } from "../../../../graphql/queries/listSegments";
import { getDateWithFormat } from "../../../../utils/commons";
import { toCamelCase } from "../../../../utils/functions";
import SummaryCard from "./SummaryCard";

const AdditionalField = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="additional-fields-container">
      <div className="field-pair">
        <span className="field-label">{label}</span>
      </div>
      {typeof value === "boolean" ? (
        <div className="field-pair-two-column">
          <span className="field-value">{value ? "Yes" : "No"}</span>
        </div>
      ) : (
        <div className="field-pair-two-column">
          <span className="field-value">{value}</span>
        </div>
      )}
    </div>
  );
};

const AdditionalImage = ({ label, value }) => {
  return value ? (
    <div className="additional-fields-container">
      <div className="field-pair">
        <span className="field-label">{label}</span>
      </div>

      <div className="field-pair-two-column">
        <img width={180} height={120} src={value} alt={label} />
      </div>
    </div>
  ) : null;
};

const SummaryBasicSetup = (props) => {
  const { data, editSectionHandler, isViewScreen, inputSchema, contentSchema } =
    props;
  const [rewardCategories, setRewardCategories] = useState([]);
  const [termAndConditionModalOpen, setTermAndConditionModalOpen] = useState(false);
  const { programTimezone } = useContext(GlobalConfigContext);
  const { data: rewardCategoriesList } = useQuery(listRewardCategories);
  const { data: segmentsData } = useQuery(listSegments);
  const { data: keywordGroupsList } = useQuery(matchKeywordGroupsList, {
    variables: { type: "BRAND" },
  });
  const campaignTypeProperties = inputSchema?.properties || null;
  const isProgramPTR = window.REACT_APP_PROGRAM === "ptr";
  const contentData = data?.campaignContent?.contentData || null;
  const genericReward = contentData?.generic_reward_content || null;

  const selectedBrands = data?.brands?.map(
    (item) =>
      keywordGroupsList?.listKeywordGroups?.find((brand) => brand.id === item) || {}
  );
  const selectedSegments = data?.segments?.map(
    (item) =>
      segmentsData?.listSegments?.find((segment) => segment.id === item) || {}
  );
  const getTitles = (name, schema) => {
    let title = "";
    if (schema && schema[name]) {
      title = schema[name]?.title;
    }
    return title || toCamelCase(name);
  };
  const renderTitleSwitch = (key) => {
    const titleMap = {
      confirmButtonText: "Popover Confirm Button Text",
      rejectButtonText: "Popover Reject Button Text",
      popoverSetup: "Popover Setup",
      walletBannerSetup: "Wallet Banner Setup",
      buttonText: "Button Text",
      enable: "Enable",
    };
    return titleMap[key] || toCamelCase(key);
  };
  const renderSwitch = (key, value) => {
    const renderMap = {
      campaign_image_url: (
        <img height={98} width={177} src={value} alt="home page img" />
      ),
      image: <img height={98} width={177} src={value} alt="image" />,
      detail_img_url: <img height={98} width={177} src={value} alt="detail img" />,
      terms_and_conditions: (
        <Button
          onClick={toggleTCModal}
          iconLeadingVariant="outlined"
          icontrailingvariant="outlined"
          size="small"
          text="View Terms and Conditions"
          variant="secondary"
        />
      ),
      popoverSetup: (
        <>
          {Object.entries(value).map(([key1, index1]) =>
            key1 === "image" ? (
              <AdditionalImage
                label="Popover Image"
                value={index1}
                key="popoverImage"
              />
            ) : (
              <AdditionalField
                key={key1}
                label={renderTitleSwitch(key1)}
                value={!!value[key1]}
              />
            )
          )}
        </>
      ),
      walletBannerSetup: value && (
        <>
          {Object.entries(value).map(([key1, index1]) =>
            key1 === "image" ? (
              <AdditionalImage
                label="Wallet Banner Image"
                value={index1}
                key="walletBannerImage"
              />
            ) : (
              <AdditionalField
                key={key1}
                label={renderTitleSwitch(key1)}
                value={index1}
              />
            )
          )}
        </>
      ),
    };

    return (
      renderMap[key] || <span className="field-value">{value?.toString()}</span>
    );
  };
  useEffect(() => {
    if (rewardCategoriesList && rewardCategoriesList?.listRewardCategories) {
      const rewardItems = rewardCategoriesList?.listRewardCategories?.items;
      let rewardItemNames = [];
      rewardItems?.forEach((category) => {
        if (data?.rewardCategories?.includes(category?.id)) {
          rewardItemNames.push(category?.name);
        }
      });
      if (rewardItemNames) {
        setRewardCategories(rewardItemNames);
      }
    }
  }, [rewardCategoriesList, data?.rewardCategories]);

  const getFormattedDate = (date, timezone, format = "MM/DD/YYYY hh:mm A") =>
    getDateWithFormat(date, timezone, format) || "No end date";
  const toggleTCModal = () => {
    const isTermsAndConditionsUri =
      inputSchema?.properties?.terms_and_conditions?.format === "uri";
    if (isTermsAndConditionsUri) {
      const termsAndConditionsUrl = contentData?.terms_and_conditions;
      window.open(termsAndConditionsUrl, "_blank", "noopener, noreferrer");
    } else {
      setTermAndConditionModalOpen(!termAndConditionModalOpen);
    }
  };

  return (
    <SummaryCard
      title="Basic"
      isViewScreen={isViewScreen}
      handleEditSection={editSectionHandler}
    >
      {data?.displayAfterEndDate && (
        <AdditionalField
          label={
            isProgramPTR
              ? "When activity is completed, show campaign card as"
              : "Show Campaign completion as..."
          }
          value={toCamelCase(data?.displayAfterEndDate)}
        />
      )}
      <AdditionalField label="Campaign ID" value={contentData?.id || data?.id} />
      <AdditionalField
        label="Campaign Name"
        value={contentData?.title || data?.title}
      />
      {contentData && (
        <>
          <AdditionalField
            label={getTitles("title_short", contentSchema?.properties)}
            value={contentData?.title_short}
          />
          <AdditionalField
            label={getTitles("description", contentSchema?.properties)}
            value={contentData?.description}
          />
        </>
      )}
      <AdditionalField
        label="Campaign Active Date"
        value={`${
          data?.activeStartDate
            ? getFormattedDate(data?.activeStartDate, programTimezone)
            : "N/A"
        } - ${
          data?.activeEndDate
            ? getFormattedDate(data?.activeEndDate, programTimezone)
            : "N/A"
        }`}
      />
      {(!campaignTypeProperties || campaignTypeProperties?.endless) && (
        <AdditionalField
          label="Always Active"
          value={data?.endless ? "Yes" : "No"}
        />
      )}
      {(!campaignTypeProperties ||
        (campaignTypeProperties?.displayStartDate &&
          campaignTypeProperties?.displayEndDate)) && (
        <AdditionalField
          label="Campaign Display Date"
          value={`${getFormattedDate(
            data?.displayStartDate || data?.activeStartDate,
            programTimezone
          )} - ${getFormattedDate(
            data?.displayEndDate || data?.activeEndDate,
            programTimezone
          )}`}
        />
      )}
      {selectedBrands &&
        (!campaignTypeProperties || campaignTypeProperties?.brands) && (
          <AdditionalField
            label="Brand"
            value={selectedBrands?.map((brand) => brand.name).join(", ")}
          />
        )}
      {(!campaignTypeProperties || campaignTypeProperties?.tags) && (
        <AdditionalField
          label="Tags"
          value={data?.tags?.map((tag) => tag).join(", ")}
        />
      )}
      {(!campaignTypeProperties ||
        (campaignTypeProperties?.hasShowInHistory &&
          campaignTypeProperties?.hasShowEntriesCount)) && (
        <>
          <AdditionalField
            label={
              isProgramPTR
                ? "Show number of entries in campaign card"
                : "Show in Campaign card"
            }
            value={data?.hasShowEntriesCount ? "On" : "Off"}
          />
          <AdditionalField
            label="Show in Member Campaign History"
            value={data?.hasShowInHistory ? "On" : "Off"}
          />
        </>
      )}
      <AdditionalField label="Campaign category" value={data?.category} />
      <AdditionalField
        label="Rewards Category"
        value={rewardCategories.toString()}
      />
      <AdditionalField
        label={getTitles("token", contentSchema?.properties)}
        value={data?.token}
      />

      {contentData && (
        <>
          <AdditionalField
            label={getTitles("participation_message", contentSchema?.properties)}
            value={contentData?.participation_message}
          />
          <AdditionalField
            label={getTitles("what_should_i_buy", contentSchema?.properties)}
            value={contentData?.what_should_i_buy}
          />
          <AdditionalImage
            label={getTitles("campaign_image_url", contentSchema?.properties)}
            value={contentData?.campaign_image_url}
          />
          <AdditionalImage
            label={getTitles("detail_img_url", contentSchema?.properties)}
            value={contentData?.detail_img_url}
          />
          {contentData?.show_in_homepage && (
            <AdditionalField label="Position" value={contentData?.position} />
          )}

          {genericReward && (
            <div>
              <h4 className="mt-30 mb-10">Generic Reward</h4>
              <AdditionalField
                label={getTitles(
                  "generic_reward_image",
                  contentSchema?.properties?.generic_reward_content?.properties
                )}
                value={renderSwitch("image", genericReward?.generic_reward_image)}
              />
              <AdditionalField
                label={getTitles(
                  "generic_reward_name",
                  contentSchema?.properties?.generic_reward_content?.properties
                )}
                value={genericReward?.generic_reward_name}
              />
              <AdditionalField
                label={getTitles(
                  "generic_reward_congratulation_message",
                  contentSchema?.properties?.generic_reward_content?.properties
                )}
                value={genericReward?.generic_reward_congratulation_message}
              />
              <AdditionalField
                label={getTitles(
                  "generic_reward_selection_message",
                  contentSchema?.properties?.generic_reward_content?.properties
                )}
                value={genericReward?.generic_reward_selection_message}
              />
            </div>
          )}

          {!isProgramPTR && (
            <AdditionalField
              label="Popover Setup"
              value={renderSwitch(
                "popoverSetup",
                contentData?.popoverSetup || { enable: false }
              )}
            />
          )}
          {!isProgramPTR && contentData?.walletBannerSetup && (
            <AdditionalField
              label="Wallet Banner Setup"
              value={renderSwitch(
                "walletBannerSetup",
                contentData?.walletBannerSetup
              )}
            />
          )}

          {contentData?.terms_and_conditions && (
            <AdditionalField
              label="Terms and Conditions"
              value={renderSwitch(
                "terms_and_conditions",
                contentData?.terms_and_conditions
              )}
            />
          )}
        </>
      )}

      {selectedSegments?.length > 0 && (
        <AdditionalField
          label="Segments"
          value={selectedSegments?.map((segment) => segment.name).join(", ")}
        />
      )}
      {termAndConditionModalOpen && (
        <TermAndConditionModal
          termAndConditionModalOpen={termAndConditionModalOpen}
          toggleTCModal={toggleTCModal}
          tcData={contentData?.terms_and_conditions}
        />
      )}
    </SummaryCard>
  );
};
export default SummaryBasicSetup;
