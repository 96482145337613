import { Button, File, LoadingSpinner } from "pepsico-ds";
import PropTypes from "prop-types";
import { useState } from "react";
import { useBynder } from "../../hooks/useBynderHook";
import useFakeProgressValue from "../../hooks/useFakeProgressValue";
import { FeatureToggle, isFeatureEnabled } from "../../utils/featureToggle";
import "./ImagePicker.scss";

export const ImagePicker = ({
  accept = ".jpg, .jpeg, .png",
  className,
  description = "Select an image",
  disabled = false,
  maxSize = 1000,
  multiple = true,
  onUpdate,
}) => {
  const bynder = useBynder();
  const { initProgress, finishProgress, resetProgress, progressValue } =
    useFakeProgressValue();
  const [uploadError, setUploadError] = useState();

  const [galleryImages, setGalleryImages] = useState([]);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [isGalleryLoading, setIsGalleryLoading] = useState(false);
  const [galleryError, setGalleryError] = useState();

  const [selectedImage, setSelectedImage] = useState(null);

  const selectedFileUploadDidChange = async (file) => {
    const fileExtension = file?.name?.split(".").pop();
    const hasIncludeExt = accept.includes(fileExtension);
    if (!hasIncludeExt) {
      setUploadError(
        `Invalid file type! Allowed files types are ${accept.toString()}`
      );
      return;
    }

    if (file?.size && Math.ceil(file?.size / 1000) > maxSize) {
      const isMb = maxSize >= 1000;
      const size = isMb ? maxSize / 1000 : maxSize;
      const sizeUnit = isMb ? "MB" : "KB";

      setUploadError(
        `Image is too large! Maximum size allowed is ${size}${sizeUnit}.`
      );
      return;
    }

    resetProgress();
    setUploadError();

    try {
      const abortController = new AbortController();
      const mediaInfoResult = await bynder.upload({
        file,
        title: file.name,
        updatePercentage: initProgress,
        abortController,
      });

      finishProgress();
      setTimeout(() => {
        onConfirmImageSelection(mediaInfoResult);
      }, 100);
    } catch (err) {
      console.error("imagePicker::error", err);
      resetProgress();
      setUploadError("Error saving image! Please try again later.");
    }
  };

  const openImageGallery = () => {
    setGalleryError();
    setIsGalleryOpen(true);
    loadGalleryAssets();
  };

  const closeImageGallery = () => {
    setIsGalleryOpen(false);
  };

  const loadGalleryAssets = async () => {
    setIsGalleryLoading(true);

    try {
      const assets = await bynder.getAssetsByTag();
      setGalleryImages(assets);
      return assets;
    } catch (err) {
      console.error("imagePicker::error", err);
      setGalleryError("Error loading images! Please try again later.");
    } finally {
      setIsGalleryLoading(false);
    }
  };

  const onThumbnailDidClick = (asset) => {
    setSelectedImage(asset.id === selectedImage?.id ? null : asset);
  };

  const onCancelGalleryImageSelection = () => {
    closeImageGallery();
    setSelectedImage(null);
  };

  const onConfirmImageSelection = async (imageAsset) => {
    const asset = imageAsset ?? selectedImage;
    onUpdate(asset.original, asset.name);

    closeImageGallery();
  };

  const isUploading = progressValue !== undefined && progressValue > 0;
  const isNoResults = !galleryImages?.media?.length;

  return (
    <>
      <div className="image-uploader" data-testid="image-uploader">
        <File
          data-testid="image-uploader-file"
          accept={accept}
          description={description}
          buttonLabel="Upload image"
          type="image"
          files={selectedImage ? [selectedImage.name] : []}
          variant={isUploading ? "uploading" : "default"}
          progressPerc={progressValue}
          onUpdate={selectedFileUploadDidChange}
          onFileError={selectedFileUploadDidChange}
          disabled={disabled || isUploading}
          error={uploadError}
          feedbackType={uploadError ? "error" : undefined}
          className={className}
          multiple={multiple}
          helperText={uploadError}
        />
        {isFeatureEnabled(FeatureToggle.imagePicker) && (
          <Button
            data-testid="btn-gallery"
            className="btn-gallery"
            size="small"
            onClick={openImageGallery}
            iconTrailing="image"
            disabled={disabled || isUploading}
          >
            Select image from gallery
          </Button>
        )}
      </div>

      {isGalleryOpen && (
        <div className="image-picker-wrapper" data-testid="image-picker">
          <h3 className="image-picker-wrapper__title">Image Gallery</h3>

          <div className="image-picker">
            {galleryError && (
              <div className="image-picker__error" data-testid="image-picker-error">
                {galleryError}
              </div>
            )}

            {isGalleryLoading ? (
              <div
                className="image-picker__loader"
                data-testid="image-picker-loading"
              >
                <LoadingSpinner size={{ width: "2rem", height: "2rem" }} />
              </div>
            ) : (
              <>
                {isNoResults && (
                  <div
                    className="image-picker__empty"
                    data-testid="image-picker-empty"
                  >
                    No images found
                  </div>
                )}

                {!isNoResults && (
                  <div
                    className="image-picker__items"
                    data-testid="image-picker-success"
                  >
                    {galleryImages?.media?.map((asset) => {
                      const isImageSelected = selectedImage?.id === asset.id;

                      return (
                        <div
                          key={asset.id}
                          className={`image-picker__thumbnail ${isImageSelected ? "selected" : ""}`}
                          data-testid="image-picker-thumbnail"
                          onClick={() => onThumbnailDidClick(asset)}
                          role="button"
                          tabIndex="0"
                        >
                          <img
                            className="image-picker__image"
                            src={asset.thumbnails.thul}
                            alt={asset.property_Product_Description ?? asset.name}
                          />
                          <small
                            className="image-picker__label"
                            title={asset.property_Product_Description ?? asset.name}
                          >
                            {asset.property_Product_Description ?? asset.name}
                          </small>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="image-picker__actions">
            <Button
              data-testid="btn-close-gallery"
              className="btn-close-gallery"
              variant="secondary"
              size="medium"
              onClick={onCancelGalleryImageSelection}
            >
              Cancel
            </Button>
            {galleryImages?.media?.length > 0 && (
              <Button
                data-testid="btn-confirm-selection"
                className="btn-confirm-selection"
                size="medium"
                onClick={() => onConfirmImageSelection()}
                disabled={!selectedImage}
              >
                Confirm selected image
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ImagePicker.propTypes = {
  accept: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
};
