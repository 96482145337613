import { createAjv } from "@jsonforms/core";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, Toggle } from "pepsico-ds";
import { useMemo, useState } from "react";
import JsonFormsWrapper from "../../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

import {
  getPartnerCodeGroupUISchema,
  partnerCodeGroupJsonSchema,
} from "../../schemas/partnerCodeGroupSchema";

import { sampleText } from "../../partnerCodeGroupSummary/sampleFile";
import "./createPartnerCodeModal.scss";

function PartnerCodeGroupFormModal({
  isOpen,
  partnerCodeGroupData,
  setPartnerCodeGroupData,
  handleMutation,
  loading,
  title,
  toggleCreatePartnerCodeModal,
  isEditMode = false,
  readonly = false,
}) {
  const ajvInstance = useMemo(() => createAjv(), []);
  const uiSchema = getPartnerCodeGroupUISchema(isEditMode);
  const jsonSchema = partnerCodeGroupJsonSchema;
  const [error, setError] = useState(null);

  const isValidForm = ajvInstance.validate(jsonSchema, partnerCodeGroupData);

  const handleIsActive = (isActive) => {
    setPartnerCodeGroupData((prev) => ({ ...prev, isActive }));
  };

  const handleDownloadFile = async () => {
    try {
      const blob = new Blob([sampleText()], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "sampleFile.txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setError(null);
    } catch (error) {
      console.error("There was an error downloading the file:", error);
      setError(`There was an error downloading the file: ${error}`);
    }
  };

  const isReadyForActive = () => {
    const startDate = new Date(partnerCodeGroupData?.startDate);
    const today = new Date();
    return startDate.getTime() <= today.getTime();
  };

  return (
    <Dialog
      className="partner-code-group-modal"
      open={isOpen}
      onClose={toggleCreatePartnerCodeModal}
      scroll="paper"
      fullWidth
      maxWidth="large"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={10}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
              {title} Partner Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon onClick={toggleCreatePartnerCodeModal} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <div className={`code-group-mod ${isEditMode ? "edit-form" : ""}`}>
          <JsonFormsWrapper
            jsonschema={jsonSchema}
            uischema={uiSchema}
            data={partnerCodeGroupData}
            onChange={setPartnerCodeGroupData}
            readonly={readonly}
          />
          {!isEditMode && (
            <div>
              <Button
                size="small"
                variant="secondary"
                onClick={() => {
                  handleDownloadFile();
                }}
                className="download-sample-file"
              >
                Download sample file <DownloadIcon fontSize="small" />
              </Button>
              {error && <p className="errorMessage">{error}</p>}
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {isEditMode && (
          <Toggle
            label="Active"
            onUpdate={handleIsActive}
            size="medium"
            checked={partnerCodeGroupData?.isActive}
            disabled={!isReadyForActive()}
          />
        )}
        <Button onClick={handleMutation} disabled={!isValidForm || loading}>
          {title === "Edit" ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PartnerCodeGroupFormModal;
