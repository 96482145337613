import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import createRewardPartnerCodeGroup from "../graphql/mutations/createRewardPartnerCodeGroup";

export const useCreatePartnerCodeGroupMutation = () => {
  const [createPartnerCodeGroupMutation, { loading }] = useMutation(
    createRewardPartnerCodeGroup
  );

  const create = useCallback(
    async (onSuccess, onFailure, partnerCodeGroupData) => {
      const payload = {
        ...partnerCodeGroupData,
      };

      try {
        const response = await createPartnerCodeGroupMutation({
          variables: {
            request: payload,
          },
        });

        if (response?.data?.createRewardPartnerCodeGroup?.id) {
          onSuccess();
        }
      } catch (error) {
        onFailure(error);
      }
    },
    [createPartnerCodeGroupMutation]
  );

  return [create, loading];
};
