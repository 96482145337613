import { withJsonFormsControlProps } from "@jsonforms/react";
import { useEffect } from "react";
import TextInputField from "./TextInputField";

const TextInputComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
    visible,
  } = props;

  useEffect(() => {
    if (!enabled && data) {
      handleChange(path, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  const updateValue = (newVal) =>
    handleChange(path, schema.type === "string" ? newVal.trim() : newVal);

  return (
    <TextInputField
      value={data}
      disabled={!enabled}
      label={uischema.showLabel === false ? "" : label}
      updateValue={updateValue}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      visible={visible}
    />
  );
};

export const TextInputControl = withJsonFormsControlProps(TextInputComponent);
