import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useContext, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";

const CampaignTypeSelectComponent = (props) => {
  const { handleChange, label, path, required, uischema, visible, enabled } = props;

  const [selected, setSelected] = useState([]);
  const { campaignsConfiguration } = useContext(GlobalConfigContext);

  const campaignOptions = campaignsConfiguration.map((campaign) => ({
    id: campaign.key,
    displayText: campaign.value?.label || campaign.name || campaign.key || "",
  }));

  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);

    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap">
      <Dropdown
        label={label}
        childList={campaignOptions}
        selection="multiple"
        isRequired={required}
        disabled={!enabled}
        selectedValue={selected || undefined}
        placeholder={uischema.placeholder}
        setSelectedValue={handleSelectValue}
      />
    </div>
  ) : null;
};

export const CampaignTypeSelectControl = withJsonFormsMultiEnumProps(
  CampaignTypeSelectComponent
);
