import { useMutation } from "@apollo/client";
import moment from "moment";
import { GENERATE_DAM_TOKEN } from "../graphql/mutations/bynderMutations";
import Bynder from "../utils/bynderSdk";

const POLLING_INTERVAL = 2000;
const MAX_POLLING_ATTEMPTS = 60;

export function useBynder() {
  const [generateDamTokenMutation] = useMutation(GENERATE_DAM_TOKEN);

  async function makeSdkToken() {
    async function fetchAuthToken() {
      try {
        const {
          data: { generateDamToken: token },
        } = await generateDamTokenMutation();
        if (!token)
          throw new Error("[DAM] Could not retrieve Bynder's access token!");
        return token;
      } catch (error) {
        throw new Error(error);
      }
    }

    const { expiresAt, accessToken } = await fetchAuthToken();
    const expirationDate = moment(expiresAt).subtract(5, "minutes");

    return {
      expired: function () {
        const isTokenExpired = moment().isAfter(expirationDate);
        return isTokenExpired;
      },
      refresh: makeSdkToken,
      token: {
        access_token: accessToken,
      },
    };
  }

  const bynderFactory = async () => {
    const token = await makeSdkToken();

    return new Bynder({
      baseURL: window.REACT_APP_BYNDER_BASE_URL,
      token,
    });
  };

  async function upload({
    file,
    title,
    entityId = "",
    updatePercentage,
    abortController,
  }) {
    const bynderMetaProperties = await getBynderMetapropsForFile();

    const bynder = await bynderFactory();
    const resultAssetUpload = await bynder.uploadFile(
      {
        filename: `PEPL-${file.name}`,
        body: file,
        data: {
          // Bynder's API access token shouldn't have any query permissions,
          // and there should only be one brand on the whole environment, so we
          // are keeping it as an environment variable. If the number of brands
          // were to change, we would need a CAF endpoint to query the brand to
          // upload into.
          brandId: window.REACT_APP_BYNDER_BRAND_ID,
          name: title,
          ...metaProsAdapter(bynderMetaProperties),
          [metaPropKeyPrefix(bynderMetaProperties.integrationSourceId.key)]:
            entityId,
          [metaPropKeyPrefix(bynderMetaProperties.inMarketDate.key)]:
            moment().format("MM/DD/YYYY"),
          [metaPropKeyPrefix(bynderMetaProperties.expiryDate.key)]: moment()
            .add(5, "year")
            .format("MM/DD/YYYY"),
        },
      },
      ({ chunks = 1, chunksUploaded = 0 }) => {
        const percentage = (chunksUploaded * 100) / chunks;
        updatePercentage(Math.floor(percentage));
      },
      abortController
    );

    if (resultAssetUpload.statuscode) {
      // This condition should only be met if we get an error from the Bynder
      // API. Otherwise, the asset will be returned.
      throw new Error(
        `An error occurred during the Bynder upload flow of ${file.name}. Bynder API response: ${JSON.stringify(resultAssetUpload, null, 2)}`
      );
    }

    const response = await waitForSaveUploadedFileSync(resultAssetUpload);
    return response;
  }

  const waitForSaveUploadedFileSync = async ({ mediaid }) => {
    return new Promise((resolve, reject) => {
      pollMediaInfo(mediaid, resolve, reject, MAX_POLLING_ATTEMPTS);
    });
  };

  const pollMediaInfo = async (mediaid, resolve, reject, retry) => {
    if (retry < 0) {
      reject("Image upload sync timeout!");
      return;
    }

    const mediaInfoResult = await getMediaInfo(mediaid);
    if (mediaInfoResult.original) {
      setTimeout(() => resolve(mediaInfoResult), 0);
      return;
    }

    setTimeout(
      () => pollMediaInfo(mediaid, resolve, reject, retry - 1),
      POLLING_INTERVAL
    );
    setTimeout(
      () => pollMediaInfo(mediaid, resolve, reject, retry - 1),
      POLLING_INTERVAL
    );
  };

  async function getAssetsByCollection(collectionId) {
    const bynder = await bynderFactory();
    return bynder.getAssetsByCollection(collectionId);
  }

  async function getAssetsByTag(tag = window.REACT_APP_PROGRAM) {
    const bynder = await bynderFactory();
    return bynder.getAssetsByTag(tag);
  }

  async function getMediaInfo(id) {
    const bynder = await bynderFactory();
    return bynder.getMediaInfo({ id });
  }

  return {
    upload,
    getAssetsByTag,
    getAssetsByCollection,
    getMediaInfo,
  };
}

// MARK: - Bynder metaproperties

const getBynderMetapropsForFile = async () => {
  const fileName =
    window.REACT_APP_ENV.toLowerCase() !== "prod"
      ? "metaproperties.ite.js"
      : "metaproperties.js";
  const { bynderMetaProperties } = await import(`../assets/bynder/${fileName}`);
  return bynderMetaProperties;
};

const metaProsAdapter = (metaProperties) => {
  return Object.values(metaProperties).reduce((map, property) => {
    const values = property[window.REACT_APP_PROGRAM];
    if (!values || !values.length) return map;

    return {
      ...map,
      [metaPropKeyPrefix(property.key)]: values.join(),
    };
  }, {});
};

const metaPropKeyPrefix = (x) => `metaproperty.${x}`;
