export const getPartnerCodeGroupUISchema = (isPartnerIdReadonly) => ({
  type: "HorizontalLayout",
  elements: [
    {
      component: "partnerFileUpload",
      scope: "#/properties/rewardItemFileData",
      type: "Control",
      accept: ".txt, .csv",
      description: "Select a file",
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/startDate",
              component: "dateTimePicker",
              minimum: "currentDateTime",
            },
            {
              type: "Control",
              scope: "#/properties/endDate",
              component: "dateTimePicker",
              minimum: "currentDateTime",
              rule: {
                condition: {
                  schema: { gt: "startDate" },
                },
                effect: "VALIDATE",
              },
            },
          ],
        },
        {
          type: "Control",
          scope: "#/properties/name",
          component: "text",
        },
        {
          type: "Control",
          scope: "#/properties/description",
          component: "textarea",
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/partnerId",
          component: "partnerSelect",
          options: { readonly: isPartnerIdReadonly },
        },
        {
          type: "Control",
          scope: "#/properties/codeWarningLimitPercentage",
          component: "codeWarningLimit",
        },
      ],
    },
  ],
});

export const partnerCodeGroupJsonSchema = {
  type: "object",
  properties: {
    rewardItemFileData: {
      type: "array",
      items: { type: "object" },
      title: "Import Code",
      minItems: 1,
    },
    startDate: { type: "string", title: "Start Date" },
    endDate: { type: "string", title: "End Date" },
    name: { type: "string", title: "Name", minLength: 1 },
    description: { type: "string", title: "Description", minLength: 1 },
    partnerId: { type: "string", title: "Partner" },
    codeWarningLimitPercentage: {
      type: "number",
      title: "Code Warning Limit",
      default: 0,
    },
    isActive: { type: "boolean", title: "Is Active", default: false },
  },
  required: [
    "rewardItemFileData",
    "startDate",
    "endDate",
    "name",
    "description",
    "partnerId",
    "codeWarningLimitPercentage",
    "isActive",
  ],
};
